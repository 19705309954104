import { ReportComponent } from './pages/webadmin/report/report.component';
import { ImportazioneSdsComponent } from './pages/webadmin/importazione-sds/importazione-sds.component';
import { SchedaImportazioneSdsComponent } from './pages/webadmin/importazione-sds/scheda-importazione-sds/scheda-importazione-sds.component';
import { RichiestaComponent } from './pages/supporto/richiesta/richiesta.component';
import { SchedaTicketComponent } from './pages/webadmin/tickets/scheda-ticket/scheda-ticket.component';
import { TicketsComponent } from './pages/webadmin/tickets/tickets.component';
import { SchedaOrdineComponent } from './pages/shops/ordini/scheda-ordine/scheda-ordine.component';
import { SchedaClienteComponent as ShopClienteComponent} from './pages/shops/clienti/scheda-cliente/scheda-cliente.component';
import { OrdiniComponent as ShopOrdini} from './pages/shops/ordini/ordini.component';
import { ClientiComponent as ShopClienti} from './pages/shops/clienti/clienti.component';
import { ProdottiComponent  as ShopProdotti } from './pages/shops/prodotti/prodotti.component';
import { SchedaProdottoComponent as ShopSchedaProdotto} from './pages/shops/prodotti/scheda-prodotto/scheda-prodotto.component';
import { EditPasswordComponent } from './pages/edit-password/edit-password.component';
import { SchedaNegozioComponent } from './pages/webadmin/negozi/scheda-negozio/scheda-negozio.component';
import { NuovoAbbonamentoComponent } from './pages/webadmin/abbonamenti/nuovo-abbonamento/nuovo-abbonamento.component';
import { DownloadComponent } from './pages/download/download.component';
import { ProfiloComponent as ApolicatorProfilo} from './pages/applicators/profilo/profilo.component';
import { NuovoClienteComponent as ApplicatorNuovoCliente } from './pages/applicators/clienti/nuovo-cliente/nuovo-cliente.component';
import { SchedaProdottoComponent } from './pages/applicators/prodotti/scheda-prodotto/scheda-prodotto.component';
import { NuovoAppaltoComponent } from './pages/applicators/appalti/nuovo-appalto/nuovo-appalto.component';
import { SupportoComponent } from './pages/supporto/supporto.component';
import { ProdottiComponent  as ApplicatorsProdotti } from './pages/applicators/prodotti/prodotti.component';
import { ClientiComponent as ApplicatoriClienti } from './pages/applicators/clienti/clienti.component';
import { ProduttoriComponent } from './pages/webadmin/produttori/produttori.component';
import { NegoziComponent } from './pages/webadmin/negozi/negozi.component';
import { ApplicatoriComponent } from './pages/webadmin/applicatori/applicatori.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { AuthGuard } from './auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProdottiComponent } from './pages/webadmin/prodotti/prodotti.component';
import { AppaltiComponent } from './pages/applicators/appalti/appalti.component';
import { SchedaAppaltoComponent } from './pages/applicators/appalti/scheda-appalto/scheda-appalto.component';
import { SchedaClienteComponent } from './pages/applicators/clienti/scheda-cliente/scheda-cliente.component';
import { LicenzaComponent } from './pages/applicators/licenza/licenza.component';
import { SchedaProdottoComponent as AdminSchedaProdotto } from './pages/webadmin/prodotti/scheda-prodotto/scheda-prodotto.component';
import { SchedaApplicatoreComponent } from './pages/webadmin/applicatori/scheda-applicatore/scheda-applicatore.component';
import { SchedaProduttoreComponent } from './pages/webadmin/produttori/scheda-produttore/scheda-produttore.component';
import { NuovoProdottoComponent } from './pages/webadmin/prodotti/nuovo-prodotto/nuovo-prodotto.component';
import { SubscribeExpiredComponent } from './pages/subscribe-expired/subscribe-expired.component';
import { ProfiloComponent  as AdminProfiloComponent } from './pages/webadmin/profilo/profilo.component';

const routes: Routes = [
  { path: '', component: DashboardComponent, pathMatch: 'full', canActivate: [AuthGuard]  },
  { path: 'login', component: LoginComponent},
  { path: 'subscribe-expired/:mail', component: SubscribeExpiredComponent },
  { path: 'password-recovery', component: EditPasswordComponent },
  // common under authguard
  { path: 'logout', component: LogoutComponent, canActivate: [AuthGuard]},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  { path: 'download', component: DownloadComponent, canActivate: [AuthGuard]},
  { path: 'supporto', component: SupportoComponent, canActivate: [AuthGuard]},
 // { path: 'supporto/richiesta', component: RichiestaComponent, canActivate: [AuthGuard]},
  // webadmin
  { path: 'admin/applicatori', component: ApplicatoriComponent, canActivate: [AuthGuard]},
  { path: 'admin/applicatori/scheda-applicatore/:id', component: SchedaApplicatoreComponent, canActivate: [AuthGuard]},
  { path: 'admin/negozi', component: NegoziComponent, canActivate: [AuthGuard]},
  { path: 'admin/negozi/scheda-negozio/:id', component: SchedaNegozioComponent, canActivate: [AuthGuard]},
  { path: 'admin/prodotti', component: ProdottiComponent, canActivate: [AuthGuard]},
  { path: 'admin/prodotti/produttore/:id', component: ProdottiComponent, canActivate: [AuthGuard]},
  { path: 'admin/prodotti/scheda-prodotto/:id', component: AdminSchedaProdotto, canActivate: [AuthGuard]},
  { path: 'admin/prodotti/nuovo-prodotto', component: NuovoProdottoComponent, canActivate: [AuthGuard]},
  { path: 'admin/produttori', component: ProduttoriComponent, canActivate: [AuthGuard]},
  { path: 'admin/produttori/scheda-produttore/:id', component: SchedaProduttoreComponent, canActivate: [AuthGuard]},
  { path: 'admin/webadmin/profilo', component: AdminProfiloComponent, canActivate: [AuthGuard]},
  { path: 'admin/tickets', component: TicketsComponent, canActivate: [AuthGuard]},
  { path: 'admin/tickets/:id', component: SchedaTicketComponent, canActivate: [AuthGuard]},
  { path: 'admin/importazioni-schede-sds', component: ImportazioneSdsComponent, canActivate: [AuthGuard]},
  { path: 'admin/importazioni-schede-sds/:id', component: SchedaImportazioneSdsComponent, canActivate: [AuthGuard]},
  { path: 'admin/report', component: ReportComponent, canActivate: [AuthGuard]},
  // shop
  { path: 'shops/ordini', component: ShopOrdini, canActivate: [AuthGuard]},
  { path: 'shops/ordini/scheda-ordine/:id', component: SchedaOrdineComponent, canActivate: [AuthGuard]},
  { path: 'shops/clienti', component: ShopClienti, canActivate: [AuthGuard]},
  { path: 'shops/clienti/scheda-cliente/:id', component: ShopClienteComponent, canActivate: [AuthGuard]},
  { path: 'shops/prodotti', component: ShopProdotti, canActivate: [AuthGuard]},
  { path: 'shops/prodotti/scheda-prodotto/:id', component: ShopSchedaProdotto, canActivate: [AuthGuard]},
  // abbonamenti
  { path: 'abbonamenti/nuovo-abbonamento', component: NuovoAbbonamentoComponent, canActivate: [AuthGuard]},
  // applicators
  { path: 'applicator/appalti', component: AppaltiComponent, canActivate: [AuthGuard]},
  { path: 'applicator/prodotti', component: ApplicatorsProdotti, canActivate: [AuthGuard]},
  { path: 'applicator/customers', component: ApplicatoriClienti, canActivate: [AuthGuard]},
  { path: 'applicator/customers/scheda-cliente/:id', component: SchedaClienteComponent, canActivate: [AuthGuard]},
  { path: 'applicator/customers/nuovo-cliente', component: ApplicatorNuovoCliente, canActivate: [AuthGuard]},
  { path: 'applicator/nuovo-appalto', component: NuovoAppaltoComponent, canActivate: [AuthGuard]},
  { path: 'applicator/scheda-appalto/:id', component: SchedaAppaltoComponent, canActivate: [AuthGuard]},
  { path: 'applicator/scheda-prodotto/:id', component: SchedaProdottoComponent, canActivate: [AuthGuard]},
  { path: 'applicator/profilo', component: ApolicatorProfilo, canActivate: [AuthGuard]},
  { path: 'applicator/licenza', component: LicenzaComponent, canActivate: [AuthGuard]},
  { path: '**',  redirectTo: '' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
