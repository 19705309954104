import { Title } from '@angular/platform-browser';
import { Entity } from './../../../models/jsonapi.model';
import { Component, OnInit } from '@angular/core';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { interval } from 'rxjs';
import { EChartOption } from 'echarts';
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  documentsGraps : any = {
    title: {
      text: '',
      left: '58%',
      top: 'center',
      textStyle: { fontSize: 18 },
      textAlign: 'center'
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    legend: {
      left: 10,
      type: 'scroll',
      orient: 'vertical',
      data: []
    },

    series: [
      {
        name: '',
        type: 'pie',
        center: ['60%', '50%'],
        radius: ['40%', '50%'],
        label: {
          show: false
        },
        emphasis: {
          label: {
            show: false
          }
        },
        labelLine: {
          show: false
        },
        data: []
      }
    ]
  };
  errorsGraps: any = {
    title: {
      text: '',
      left: '58%',
      top: 'center',
      textStyle: { fontSize: 18 },
      textAlign: 'center'
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    legend: {
      left: 10,
      type: 'scroll',
      orient: 'vertical',
      data: []
    },

    series: [
      {
        name: '',
        type: 'pie',
        center: ['60%', '50%'],
        radius: ['40%', '50%'],
        label: {
          show: false
        },
        emphasis: {
          label: {
            show: false
          }
        },
        labelLine: {
          show: false
        },
        data: []
      }
    ]
  };
  year = null;
  loading = false;
  report = {
    documenticonsegnati: 0,
    applicatoriabbonati: 0,
    documentiproduttore: [],
    topsellerproducts: [],
    shops: 0,
    orders: 0,
    errors: [],
    topsellershops: []
  };
  constructor(private jsonapiservice: JsonapiService) { }

  ngOnInit() {
    const d = new Date();
    this.year = d.getFullYear();
    this.getData();
  }




  public drawGraphs() {

    let n = 0;
    this.errorsGraps.legend.data = [];
    this.errorsGraps.series[0].data = [];
    this.documentsGraps.legend.data = [];
    this.documentsGraps.series[0].data = [];

    this.report.errors.forEach(element => {
        n = n + parseInt(element.c);
        this.errorsGraps.legend.data.push(element.last_mail_status);
        this.errorsGraps.series[0].data.push({ value: element.c, name: element.last_mail_status });
    });

    let k = 0;
    this.report.documentiproduttore.forEach(element => {
      k = k + parseInt(element.c);
      this.documentsGraps.legend.data.push(element.manufacturer);
      this.documentsGraps.series[0].data.push({ value: element.c, name: element.manufacturer });
    });
    this.documentsGraps.title.text = k + '\n' + 'Documenti';
    this.errorsGraps.title.text = n + '\n' + 'Errori';

  }

  public getData() {

    this.loading = true;
    this.jsonapiservice.getEntity('reports', this.year).subscribe((r: Entity) => {
      this.report.documenticonsegnati = r.data.attributes.documenti_consegnati;
      this.report.applicatoriabbonati = r.data.attributes.applicatori_abbonati;
      this.report.documentiproduttore = r.data.attributes.documenti_per_produttore;
      this.report.topsellerproducts = r.data.attributes.topsellerproducts;
      this.report.topsellershops = r.data.attributes.topsellershops;
      this.report.orders = r.data.attributes.ordini;
      this.report.shops = r.data.attributes.shops;
      this.report.errors = r.data.attributes.errors;
      this.drawGraphs();
      this.loading = false;

    }, (error) => this.jsonapiservice.handlingError(error));
  }


}
