import { JsonapiService, Filter } from './../../../services/jsonapi.service';
import { User } from './../../../models/user.model';
import { Component, OnInit, NgModule } from '@angular/core';
import * as _ from 'lodash';
import { AuthService } from 'src/app/services/auth.service';
import { ContractCollection, ContractCollectionPaginated, ContractData } from 'src/app/models/contract.model';
import { MessageService } from 'src/app/services/MessageService.service';
import { DownloadService } from 'src/app/services/Download.service';
import { ConfirmDeleteComponent } from 'src/app/components/confirm-delete/confirm-delete.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { MatDialog } from '@angular/material';
import { ErrorResponce } from 'src/app/models/jsonapi.model';
import { Paginator } from 'src/app/models/paginator.model';

@Component({
  selector: 'app-appalti',
  templateUrl: './appalti.component.html',
  styleUrls: ['./appalti.component.scss']
})
export class AppaltiComponent implements OnInit {
  public paginator: Paginator;
  public appalti: Array<any>;
  public orderDirection = 'desc';
  public orderField = 'updated-at';
  public orderIcon = '';
  public selectFilter = '';
  public nameFilter = '';
  public states = ['bozza', 'terminato', 'attivo'];
  public items = null;
  public User: User;
  public loaded = false;
  public selectedItem = 0;
  public hasContracts = null;
  private readonly notifier: NotifierService;
  public allLabel;
  public confirmsendtitle;
  public confirmsendmessage;

  public loader = false;

  constructor(
    private oauth: AuthService,
    private jsonapiservice: JsonapiService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private notifierService: NotifierService,
    private downloadservice: DownloadService,

    public dialog: MatDialog
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.User = new User(this.oauth.getLoggedUser());
    // this.getContracts();

    this.getItems();
    this.messageService.filter({ event: 'selected_route', route: '/applicator/appalti' });
    this.messageService.filter({ event: 'enable_menu' });
    this.jsonapiservice.translate('i18n.applicator.contracts.statuses').subscribe((res: any) => {
      this.states = res;
    });
    this.jsonapiservice.translate('i18n.common.all').subscribe((res: any) => {
      this.allLabel = res;
    });
    this.jsonapiservice.translate('i18n.applicator.contracts.confirmsendtitle').subscribe(res => {
      this.confirmsendtitle = res;
    });
    this.jsonapiservice.translate('i18n.applicator.contracts.confirmsendmessage').subscribe(res => {
      this.confirmsendmessage = res;
    });
  }

  public getItems(sort?: string, search?: string) {
    this.loaded = false;
    this.items = [];
    this.appalti = [];
    let filters = null;
    if (search) {
      filters = [{ name: 'search', value: search }];
    }

    this.jsonapiservice.getCollection('contracts', filters, sort)
      .subscribe((data: ContractCollection) => {
        this.hasContracts = data.data.length;
        // console.log('contracts :', this.hasContracts);
        data.data.forEach((contract: ContractData) => {
          this.items.push({
            id: contract.id,
            name: contract.attributes.name,
            cliente: contract.attributes.customer,
            stato: contract.attributes.status,
            updated_at: contract.attributes['updated-at'],
            last_mail_status: contract.attributes.last_mail_status,
            rows: contract.attributes.rows
          });
        });
        this.loaded = true;
        this.appalti = this.items;
        this.orderField = 'updated_at';
        this.orderDirection = 'asc';
        this.orderBy('updated_at');
      },
        (error: ErrorResponce) => { this.jsonapiservice.handlingError(error); });
  }

  getContracts(page = 1, size = 10, filters?: Array<Filter>) {
    if (this.loader) {
      return;
    }
    this.paginator = null;
    this.loaded = false;
    this.loader = true;
    const orderby = (('desc' === this.orderDirection) ? '-' : '') + this.orderField;
    this.items = [];
    this.appalti = [];
    const items = [];
    this.jsonapiservice.getCollectionPaginated('contracts', page, size, filters, orderby)
      .subscribe((data: ContractCollectionPaginated) => {
        this.hasContracts = data.data.length;
        console.log('contracts :', this.hasContracts);
        data.data.forEach((contract: ContractData) => {
          items.push({
            id: contract.id,
            name: contract.attributes.name,
            cliente: contract.attributes.customer,
            stato: contract.attributes.status,
            updated_at: contract.attributes['updated-at'],
            last_mail_status: contract.attributes.last_mail_status,
            rows: contract.attributes.rows
          });
        });

        this.paginator = data.meta.page;
        console.log('items :', items);
        this.appalti = items;

        this.loader = false;
        this.loaded = true;
      }, (error: ErrorResponce) => this.jsonapiservice.handlingError(error));
  }

  pageClick($event) {
    this.paginator = null;
    this.appalti = null;
    this.applyFilters($event);
  }

  applyFilters(page = 1) {
    const recordPerPage = 10;
    const filters: Array<Filter> = [];
    if (this.selectFilter !== '') {
      filters.push({ name: 'status', value: this.selectFilter.toString() });
    }
    if (this.nameFilter !== '') {
      filters.push({ name: 'name', value: this.nameFilter });
    }
    this.getContracts(
      page,
      recordPerPage, filters);
  }


  /**
   * permette di scaricare la documentazione relativa all'appalto
   * @param appalto
   */
  public documentazione(appalto): void {
    const productList = [];
    appalto.rows.forEach(element => {
      productList.push(Number(element.id));
    });
    this.downloadservice.setValue('download', { products: productList });
    const newWindow = window.open('download');
  }

  /**
   * ordina gli appalti per orderField
   * @param orderField
   */
  public orderBy(orderField) {

    if (this.orderField !== orderField) {
      this.orderField = orderField;
      this.orderDirection = 'asc';
      this.orderIcon = 'fa-arrow-up';
    } else {
      this.orderDirection = (this.orderDirection === 'desc') ? 'asc' : 'desc';
      this.orderIcon = (this.orderDirection === 'desc') ? 'fa-arrow-down' : 'fa-arrow-up';
    }
    this.appalti = _.orderBy(this.appalti, [orderField], [this.orderDirection]);
  }

  public filterName(items = []) {
    if (this.nameFilter !== '') {
      const s = _.filter(items, (o) => { if (o.name.search(this.nameFilter) > -1) { return true; } });
      return s;
    } else {
      const s = _.cloneDeep(items);
      return s;
    }
  }

  public filterStato(items = []) {
    if (this.selectFilter !== '') {
      const s = _.filter(items, ['stato', this.selectFilter]);
      if (s) {
        return s;
      } else {
        return [];
      }
    } else {
      const s = _.cloneDeep(items);
      return s;
    }
  }

  public filter() {
    console.log('filter');
    const items = this.items;
    const filteredAppalti = this.filterStato(items);
    this.appalti = this.filterName(filteredAppalti);
  }

  selectItem(id) {
    this.selectedItem = (this.selectedItem === id) ? 0 : this.selectedItem = id;
  }


  public inviaAppalto(contractId) {

    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        title: this.confirmsendtitle,
        message: this.confirmsendmessage
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        //this.notifier.notify('success', 'Invio in corso');
        this.jsonapiservice.notify('success', 'i18n.applicator.contracts.sending');
        const appaltoData = {
          data: {
            id: contractId,
            type: 'contracts',
            attributes: {}
          }
        };
        this.jsonapiservice.postEntity('contracts', appaltoData, `sendpec/${contractId}`).subscribe(
          (r) => {
            this.jsonapiservice.notify('success', 'i18n.applicator.contracts.sendingsuccess');
            //
          },
          (e) => {
            // this.notifier.notify('error', 'Server momentaneamente non raggiungibile');
            this.jsonapiservice.notify('error', 'i18n.applicator.contracts.sendingerror');
          }
        );
      }
    });

  }


}
