import { Entity, ErrorResponce } from './../../../../models/jsonapi.model';
import { Attributes } from './../../../../models/user.model';
import { JsonapiService, Filter } from './../../../../services/jsonapi.service';
import { MessageService } from './../../../../services/MessageService.service';
import { Component, OnInit } from '@angular/core';
import { Product, ProductCollection } from 'src/app/models/product.model';
import { Collection } from 'src/app/models/jsonapi.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicatorProductCollectionPaginated, ApplicatorProductEntity } from 'src/app/models/applicatorproduct.model';
import { Paginator } from 'src/app/models/paginator.model';
import { NuovoClienteComponent } from '../../clienti/nuovo-cliente/nuovo-cliente.component';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-nuovo-appalto',
  templateUrl: './nuovo-appalto.component.html',
  styleUrls: ['./nuovo-appalto.component.scss']
})
export class NuovoAppaltoComponent implements OnInit {
  constructor(
    private messageService: MessageService,
    private jsonapiservice: JsonapiService,
    private router: Router,
    public dialog: MatDialog,
    private notifierService: NotifierService,
  ) {
    this.notifier = notifierService;
  }
  private readonly notifier: NotifierService;
  public phase = 0;
  public contract = {
    name: null,
    dta_end_of_works: '',
    sended: false,
    status: 'bozza',
    customer_id: null,
    products: [],
  };
  public contractId = null;
  public manufacturers: Array<any>;
  public filterManufacturer = 0;
  public customers: Array<any>;
  public search = '';
  public customer = null;
  public selectedProducts = [];
  public paginator: Paginator;
  public products: Array<ApplicatorProductEntity>;
  public orderDirection = '';
  public orderField = 'name';
  public orderIcon = '';
  public recordPerPage = 10;

  myControl = new FormControl();
  filteredOptions: Observable<any[]>;
  customerFormGroup = new FormGroup({
    rag_soc: new FormControl(),
    comune: new FormControl(),
    pec: new FormControl('', [
      Validators.required,
      Validators.pattern("[^ @]*@[^ @]*")
    ]),
    piva: new FormControl('', [
      Validators.required,
      Validators.pattern('^([0-9]{11})|([A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1})$')
    ]),
    customer_type: new FormControl()
  });

  ngOnInit() {
    this.messageService.filter({ event: 'disable_menu' });

  }

  public confirm() {

    let appaltoData = {
      data: {
        type: 'contracts',
        attributes: {
          name: this.contract.name,
          dta_end_of_works: moment(this.contract.dta_end_of_works).format('YYYY-MM-DD'),
          sended: false,
          status: 'bozza',
          customer_id: null,
          products: []
        }
      }
    };

    this.jsonapiservice.postEntity('contracts', appaltoData).subscribe((r: any) => {
      this.contractId = r.data.id;
      // this.notifier.notify('info', 'Appalto salvato come bozza');
      // redirigo sulla pagina di modifica del contratto
      this.router.navigate(['applicator/scheda-appalto/' + this.contractId]);
    }, (error: ErrorResponce) => this.jsonapiservice.handlingError(error));

  }

  exit() {
    this.router.navigate(['applicator/appalti']);
  }

}
