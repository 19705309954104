import { Entity, Collection } from 'src/app/models/jsonapi.model';
import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user.model';
import { MessageService } from 'src/app/services/MessageService.service';
import { JsonapiService, Filter } from 'src/app/services/jsonapi.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor(
    private oauth: AuthService,
    private messageService: MessageService,
    private jsonapiservice: JsonapiService
  ) { }
  public className = '';
  public user: User = null;
  public renderMenu = true;
  public selectedRoute = '';
  public userMenu = '';
  public notifyMenu = '';
  public notifiche = [];
  public nnotifiche: number;
  public ntickets: number;

  public oggi = null;

  ngOnInit() {

    const today = new Date();
    this.oggi = today.toISOString();
    this.user = new User(this.oauth.getLoggedUser());
    if (this.oauth.getToken()) {
      this.getNotification();
    }
    if (this.user.isAdmin()) {
      const filters = [];
      filters.push({name: 'todo', value: 'true'});

      console.log('admin filtri:', filters);
      this.getTickets(filters);
    }

    this.messageService.listen().subscribe((m: any) => {
      if (m.event === 'disable_menu') {
        this.renderMenu = false;
      }
      if (m.event === 'enable_menu') {
        this.renderMenu = true;
      }

      if (m.event === 'selected_route') {
        this.selectedRoute = m.route;
      }
      if (m.event === 'socket_message') {
        this.getNotification();
      }
    });

  }


  getTickets(filters?: Array<Filter>, orderby?: string) {
    this.jsonapiservice.getCollection('ticket', filters, orderby)
      .subscribe((responce: Collection) => {
        this.ntickets = responce.data.length;
      }, (error) => {
        console.error(error);
      });
  }

  getNotification() {
    const filter = [{ name: 'notifiable_id', value: this.user.getId() }];
    this.jsonapiservice.getCollection('notifications', filter).subscribe((data: any) => {
      this.notifiche = data.data;
      this.nnotifiche = data.data.length;
    },
      (error) => { }
    );
  }

  toggleUserMenu() {
    if (this.userMenu === '') {
      this.userMenu = 'show';
    } else {
      this.userMenu = '';
    }
  }

  toggleNotifyMenu() {
    if (this.notifyMenu === '') {
      this.notifyMenu = 'show';
    } else {
      this.notifyMenu = '';
    }
  }

}
