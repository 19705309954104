import { Collection, ErrorResponce } from './../../../../models/jsonapi.model';
import { Component, OnInit } from '@angular/core';
import { Data, Entity } from 'src/app/models/jsonapi.model';
import { NotifierService } from 'angular-notifier';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { JsonapiService, Filter } from 'src/app/services/jsonapi.service';
import { MessageService } from 'src/app/services/MessageService.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { error } from 'protractor';

@Component({
  selector: 'app-scheda-ticket',
  templateUrl: './scheda-ticket.component.html',
  styleUrls: ['./scheda-ticket.component.scss']
})
export class SchedaTicketComponent implements OnInit {
  public entity: Data = null;
  private readonly notifier: NotifierService;
  private resource = 'ticket';
  public translatedResources;
  public history = [];
  public historyLoaded = false;
  public priorityClasses = [`priority-toDefine`, `priority-normal`, `priority-high`, `priority-critical`];
  public statusClasses = [`status-new`, `status-handled`, `status-resolved`];
  public statuses = []; //['Nuovo', 'In carico', 'Risolto'];
  public priorities = []; // 'Da definire', 'Normale', 'Alta', 'Critica'];
  public mailsending = false;
  FormGroup = new FormGroup({
    note: new FormControl('', [Validators.required]),
    status: new FormControl(),
    priority: new FormControl()
  });


  constructor(
    private jsonapiservice: JsonapiService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private notifierService: NotifierService,
    public dialog: MatDialog,
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    // this.messageService.filter({ event: 'selected_route', route: '/admin/produttori' });
    // this.messageService.filter({ event: 'enable_menu' });
    const entityId = this.route.snapshot.paramMap.get('id');
    this.getEntity(entityId);

    this.jsonapiservice.translate('i18n.admin.tickets').subscribe((res: any) => {

      this.translatedResources = res;
      this.statuses = res.statuses;
      this.priorities = res.priorities;

    });

  }


  public getEntity(entityId) {
    this.jsonapiservice.getEntity(this.resource, entityId).subscribe((entity: Entity) => {
      this.entity = entity.data;
      //   this.FormGroup.controls.note.setValue(this.entity.attributes.message);
      this.FormGroup.controls.status.setValue(this.entity.attributes.status);
      this.FormGroup.controls.priority.setValue(this.entity.attributes.priority);
      this.getHistory(entityId);
    });
  }

  public getHistory(entityId) {
    const filters: Array<Filter> = [];
    filters.push({ name: 'ticket_id', value: entityId });
    this.historyLoaded = false;
    this.history = [];
    this.jsonapiservice.getCollection('ticket-histories', filters, '-dta_history').subscribe((history: Collection) => {
      history.data.forEach(element => {
        this.history.push({
          type: element.attributes.history_type,
          date: element.attributes.dta_history,
          history: JSON.parse(element.attributes.history)
        });
      });
      this.historyLoaded = true;
    }, (er: ErrorResponce) => {
      this.jsonapiservice.handlingError(er);
    });
  }


  getEntityAndNotifyUpdateCallback(entityId, status, message) {
    this.getEntity(entityId);
    this.notifier.notify(status, message);
  }

  updateStatus() {
    const entityId = this.route.snapshot.paramMap.get('id');
    const updateData = {
      data: {
        type: this.resource,
        id: entityId,
        attributes: {
          status: this.FormGroup.controls.status.value
        }
      }
    };

    this.jsonapiservice.updateEntity(
      this.resource,
      entityId,
      updateData).subscribe((resp) => {
        // TODO tradurre
        this.getEntityAndNotifyUpdateCallback(entityId, 'success', this.translatedResources.statusUpdatedWithSuccess);
      }, (er) => {
        this.jsonapiservice.handlingError(er);
      });
  }
  updatePriority() {
    const entityId = this.route.snapshot.paramMap.get('id');
    const updateData = {
      data: {
        type: this.resource,
        id: entityId,
        attributes: {
          priority: this.FormGroup.controls.priority.value
        }
      }
    };

    this.jsonapiservice.updateEntity(
      this.resource,
      entityId,
      updateData).subscribe((resp) => {
        this.getEntityAndNotifyUpdateCallback(entityId, 'success', this.translatedResources.priorityUpdatedWithSuccess);
      }, (er) => {
        this.jsonapiservice.handlingError(er);
      });

  }
  save() {
    this.mailsending = true;
    const entityId = this.route.snapshot.paramMap.get('id');
    const updateData = {
      data: {
        type: this.resource,
        id: entityId,
        attributes: {
          message: this.FormGroup.controls.note.value
        }
      }
    };
    this.FormGroup.controls.note.setValue('');
    this.jsonapiservice.updateEntity(this.resource, entityId, updateData).subscribe((resp) => {
      // mando una email
      this.jsonapiservice.postEntity(this.resource, updateData, `send/${entityId}`).subscribe(
        (data: any) => {
          /**
           * @todo tradurre qui
           */
          this.notifier.notify('success', 'Una email è stata mandata all\'indirizzo ' + this.entity.attributes.email);
          this.getHistory(entityId);
          this.mailsending = false;
        },
        (er: ErrorResponce) => {
          this.jsonapiservice.handlingError(er);
          this.mailsending = false;
        }
      );
    });
  }

}
