import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { MessageService } from 'src/app/services/MessageService.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ErrorResponce } from 'src/app/models/jsonapi.model';

@Component({
  selector: 'app-nuovo-cliente',
  templateUrl: './nuovo-cliente.component.html',
  styleUrls: ['./nuovo-cliente.component.scss']
})
export class NuovoClienteComponent implements OnInit {
  public customer;
  private readonly notifier: NotifierService;
  constructor(
    private jsonapiservice: JsonapiService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private notifierService: NotifierService,
    public dialog: MatDialog,
  ) {
    this.notifier = notifierService;
  }
  customerFormGroup = new FormGroup({
    rag_soc: new FormControl('', Validators.required),
    comune: new FormControl('', Validators.required),
    email: new FormControl('', [
      Validators.pattern('^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$')
    ]),
    piva: new FormControl('', [
      Validators.required,
      Validators.pattern('^([0-9]{11})|([A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1})$')
    ]),
    customer_type: new FormControl('' , [ Validators.required ])
  });
  public emailType = 'pec';

  ngOnInit() {
    this.customerFormGroup.controls['customer_type'].setValue('business');
  }

  save() {
    const data = {
      data: {
        type: 'customers',
        attributes: {
          rag_soc: this.customerFormGroup.value.rag_soc,
          piva: this.customerFormGroup.value.piva,
          email: this.customerFormGroup.value.email,
          emailtype: this.emailType,
          customer_type: this.customerFormGroup.value.customer_type,
          comune: this.customerFormGroup.value.comune
        }
      }
    };
    this.jsonapiservice.postEntity('customers', data)
    .subscribe(
      (resp) => {
        this.customer = Object.values(resp);
    }, (error: ErrorResponce) => { this.jsonapiservice.handlingError(error); });
  }

  setCustomerType(type: string) {
    this.customerFormGroup.controls['customer_type'].setValue(type);
  }

  selectEmail(emailType) {
    this.emailType = emailType;
  }
}
