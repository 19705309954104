import { Entity, ErrorResponce } from './../../../models/jsonapi.model';
import { DownloadService } from '../../../services/Download.service';
import { ApplicatorProduct, ApplicatorProductEntity, ApplicatorProductCollectionPaginated } from './../../../models/applicatorproduct.model';
import { MessageService } from './../../../services/MessageService.service';
import { JsonapiService, Filter } from './../../../services/jsonapi.service';
import { Product, ProductCollectionPaginated, ProductEntity } from './../../../models/product.model';
import { Paginator } from './../../../models/paginator.model';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { Collection } from 'src/app/models/jsonapi.model';
import { ProductsSearchDialogComponent } from 'src/app/components/products-search-dialog/products-search-dialog.component';
import { NotifierService } from 'angular-notifier';
import { ProductSuggestComponent } from './product-suggest/product-suggest.component';
import { FormGroup } from '@angular/forms';

interface ImportedResponce {
  data: Data;
}

interface Data {
  type: string;
  attributes: Attributes;
}

interface Attributes {
  imported: number;
  exists: number;
}

@Component({
  selector: 'app-prodotti',
  templateUrl: './prodotti.component.html',
  styleUrls: ['./prodotti.component.scss']
})
export class ProdottiComponent implements OnInit {
  public paginator: Paginator;
  public applicatorPaginator: Paginator;
  public products: Array<ProductEntity>;
  public applicatorProduct: Array<ApplicatorProductEntity>;

  public orderDirection = '';
  public orderField = 'name';
  public orderIcon = '';
  public recordPerPage = 10;
  public manufacturers = null;
  public filterManufacturer = 0;
  public search = '';
  private readonly notifier: NotifierService;
  public selectedItem = null;
  public skuloader = [];
  public loader = false;

  constructor(
    private jsonapiservice: JsonapiService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private notifierService: NotifierService,
    private downloadservice: DownloadService
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.orderField = 'product_imported_at';
    this.orderDirection = '-';

    this.getProducts();
    this.getApplicatorProducts();
    this.getManufacturers();
    this.messageService.filter({ event: 'selected_route', route: '/applicator/prodotti' });
    this.messageService.filter({ event: 'enable_menu' });
  }

  private getManufacturers() {
    this.manufacturers = [{ id: 0, name: 'Tutti' }];
    this.jsonapiservice.getCollection('manufacturers', null, 'rag_soc').subscribe((data: Collection) => {
      data.data.forEach((item) => {
        this.manufacturers.push({ id: item.id, name: item.attributes.rag_soc });
      });
    }, (error: ErrorResponce) => { this.jsonapiservice.handlingError(error); });
  }

  applyFilters() {
    const filters: Array<Filter> = [];
    if (this.filterManufacturer > 0) {
      filters.push({ name: 'manufacturer_id', value: this.filterManufacturer.toString() });
    }
    if (this.search !== '') {
      // filters.push({ name: 'search', value: this.search });
      filters.push({ name: 'search', value: this.search });
    }
    this.getProducts(1, this.recordPerPage, filters);
    this.getApplicatorProducts(1, this.recordPerPage, filters);
  }

  getApplicatorProducts(page = 1, size = 10, filters?: Array<Filter>) {
    const orderby = this.orderDirection + this.orderField;
    this.jsonapiservice.getCollectionPaginated('applicator-products', page, size, filters, orderby)
      .subscribe((data: ApplicatorProductCollectionPaginated) => {
        this.applicatorPaginator = data.meta.page;
        this.applicatorProduct   = data.data;
      }, (error: ErrorResponce) => { this.jsonapiservice.handlingError(error); });
  }

  getProducts(page = 1, size = 10, filters?: Array<Filter>) {
    this.paginator = null;
    this.products = [];
    this.loader = true;
    const orderby = this.orderDirection + this.orderField;
    this.jsonapiservice.getCollectionPaginated('product-view', page, size, filters, orderby)
        .subscribe((data: ProductCollectionPaginated) => {
          this.skuloader = [];
          this.paginator = data.meta.page;
          this.products = data.data;
          this.loader = false;
          console.log(filters);
        }, (error: ErrorResponce) => { this.jsonapiservice.handlingError(error); this.loader = false;  });
  }

  orderBy(orderField) {
    if (this.orderField !== orderField) {
      this.orderField = orderField;
      this.orderDirection = '';
      this.orderIcon = 'fa-arrow-up';
    } else {
      this.orderDirection = (this.orderDirection === '-') ? '' : '-';
      this.orderIcon = (this.orderDirection === '-') ? 'fa-arrow-down' : 'fa-arrow-up';
    }
    this.getProducts(this.paginator['current-page'], this.recordPerPage);
  }


  pageClick($event) {
    this.paginator = null;
    this.products = null;
    this.getProducts($event);
  }

  public suggestProduct() {
    const dialogRef = this.dialog.open(ProductSuggestComponent, {
      height: '400px',
      width: 'auto',
    });
    dialogRef.afterClosed().subscribe((result: FormGroup) => {
      if (result && result.status === 'VALID') {
        this.jsonapiservice.notify('success', 'i18n.common.suggestsended');
      }
    });
  }
/*
  openDialog() {
    const dialogRef = this.dialog.open(ProductsSearchDialogComponent, {
      data: {
        search: this.search,
        manufacturer: this.filterManufacturer
      },
      height: '500px',
      width: 'auto',
    });
    dialogRef.afterClosed().subscribe((result: Array<string>) => {
      const payload = {
        data:
        {
          type: 'applicator-products',
          attributes:
          {
            products: result
          }
        }
      };
      this.jsonapiservice.postEntity('applicator-products', payload, 'import').subscribe((resp: ImportedResponce) => {
        let message = resp.data.attributes.imported.toString() + ' prodotti sono stati importati';
        if (resp.data.attributes.exists > 0) {
          // tslint:disable-next-line: max-line-length
          message = message + '\n' + resp.data.attributes.exists.toString() + ' prodotti non sono stati importati perche` inseriti nella tua lista';
        }
        this.notifier.notify('success', message);
      });
      this.applyFilters();
    });
  }
 */
  selectItem(id) {
    this.selectedItem = (this.selectedItem === id) ? 0 : this.selectedItem = id;
  }

  addProduct(sku) {
    this.skuloader[sku] = true;
    const payload = {
      data:
      {
        type: 'applicator-products',
        attributes:
        {
          products: [sku]
        }
      }
    };
    this.jsonapiservice.postEntity('applicator-products', payload, 'import').subscribe((resp: ImportedResponce) => {
     this.applyFilters();
      //  let message = resp.data.attributes.imported.toString() + ' prodotti sono stati importati';
      let message = 'Il prodotto con codice SKU: ' + sku + ' è stato inserito correttamente nel tuo catalogo';
     if (resp.data.attributes.exists > 0) {
        // tslint:disable-next-line: max-line-length
        message = 'Il prodotto con codice SKU: ' + sku + ' esiste nel tuo catalogo';
        // message = message + '\n' + resp.data.attributes.exists.toString() + ' prodotti non sono stati importati perche` inseriti nella tua lista';
        this.notifier.notify('error', message);
      } //else {
       this.notifier.notify('success', message);
    //  }
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    });

  }

  public download(productId, language, kind) {
    this.downloadservice.setValue('download', { products: [productId], lang: language, subtype: kind });
    const newWindow = window.open('download');
  }
}
