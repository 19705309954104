import { ErrorResponce } from './../../../models/jsonapi.model';
import { DownloadService } from '../../../services/Download.service';
import { MessageService } from './../../../services/MessageService.service';
import { JsonapiService, Filter } from './../../../services/jsonapi.service';
import { Product, ProductCollectionPaginated, ProductEntity } from './../../../models/product.model';
import { Paginator } from './../../../models/paginator.model';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { Collection } from 'src/app/models/jsonapi.model';
import { ProductsSearchDialogComponent } from 'src/app/components/products-search-dialog/products-search-dialog.component';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { NuovoProdottoComponent } from './nuovo-prodotto/nuovo-prodotto.component';

interface ImportedResponce {
  data: Data;
}

interface Data {
  type: string;
  attributes: Attributes;
}

interface Attributes {
  imported: number;
  exists: number;
}

@Component({
  selector: 'app-prodotti',
  templateUrl: './prodotti.component.html',
  styleUrls: ['./prodotti.component.scss']
})
export class ProdottiComponent implements OnInit {
  public paginator: Paginator;
  public products: Array<ProductEntity>;
  public orderDirection = '';
  public orderField = 'name';
  public orderIcon = '';
  public recordPerPage = 10;
  public loading = false;
  public manufacturers = null;
  public filterManufacturer = 0;
  public search = '';
  private readonly notifier: NotifierService;
  public translatedResources;

  private timer: any;


  constructor(
    private jsonapiservice: JsonapiService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private notifierService: NotifierService,
    private downloadservice: DownloadService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {

    this.messageService.filter({ event: 'selected_route', route: '/admin/prodotti' });
    this.messageService.filter({ event: 'enable_menu' });
    this.jsonapiservice.translate('i18n.admin.products').subscribe((res: any) => {
      this.translatedResources = res;
      const manufacturerID = this.route.snapshot.paramMap.get('id');
      if (manufacturerID) {
        const filters: Array<Filter> = [];
        this.filterManufacturer =  Number(manufacturerID);
        filters.push({ name: 'manufacturer_id', value: manufacturerID.toString() });
        this.getProducts(1, this.recordPerPage, filters);
        this.getManufacturers();
      } else {
        this.getProducts();
        this.getManufacturers();
      }
    });
  }

  private getManufacturers() {
   this.manufacturers = [{ id: 0, name: this.translatedResources.all}];
   this.jsonapiservice.getCollection('manufacturers', null, 'rag_soc').subscribe((data: Collection) => {
      data.data.forEach((item) => {
        this.manufacturers.push({ id: item.id, name: item.attributes.rag_soc });
      });
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    });
  }

  applyFilters(page = 1) {
    const filters: Array<Filter> = [];
    if (this.filterManufacturer > 0) {
      filters.push({ name: 'manufacturer_id', value: this.filterManufacturer.toString() });
    }
    if (this.search !== '') {
      filters.push({ name: 'search', value: this.search });
    }

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.getProducts(
        page,
        this.recordPerPage, filters);
    }, 400 );
  }

  /**
   *
   * @param page
   * @param size
   * @param filters
   */
  getProducts(page = 1, size = 10, filters?: Array<Filter>) {
    this.products = [];
    this.loading  = true;
    const orderby = this.orderDirection + this.orderField;
    this.jsonapiservice.getCollectionPaginated('products', page, size, filters, orderby)
      .subscribe((data: ProductCollectionPaginated) => {
        this.paginator = data.meta.page;
        this.products = data.data;
        this.loading = false;
      }, (error: ErrorResponce) => {
        this.jsonapiservice.handlingError(error);
      });
  }

  orderBy(orderField) {
    if (this.orderField !== orderField) {
      this.orderField = orderField;
      this.orderDirection = '';
      this.orderIcon = 'fa-arrow-up';
    } else {
      this.orderDirection = (this.orderDirection === '-') ? '' : '-';
      this.orderIcon = (this.orderDirection === '-') ? 'fa-arrow-down' : 'fa-arrow-up';
    }
    this.getProducts(this.paginator['current-page'], this.recordPerPage);
  }


  pageClick($event) {
    this.paginator = null;
    this.products = null;
    this.applyFilters($event);
  }

  changeStyle($event) {
    const row = document.getElementById(`row-${$event.target.id}`);

    if (row) {
      if ($event.type === 'mouseover' && row.getAttribute('class') === 'moreinfo') {
        row.setAttribute('class', 'moreinfo active');
      }
      if ($event.type === 'click' ) {
        row.setAttribute('class', 'moreinfo show');
      }

      if ($event.type === 'mouseout' &&  row.getAttribute('class') === 'moreinfo active' ) {
        row.setAttribute('class', 'moreinfo');
      }
    }
  }

  public download(productId, lang='it') {
    this.downloadservice.setValue('download', { products : [productId]});
    const newWindow = window.open('download');
  }

  newProduct() {
    const dialogRef = this.dialog.open(NuovoProdottoComponent, {
      height: 'auto',
      width: '500px',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data.id) {
        this.notifier.notify('success', this.translatedResources.successinserted);
        this.router.navigateByUrl(`/admin/prodotti/scheda-prodotto/${result.data.id}`);
      }
    });
  }

}




