import { NuovoClienteComponent } from './nuovo-cliente/nuovo-cliente.component';
import { CustomerEntity, CustomerCollectionPaginated, CustmerData } from './../../../models/customer.model';
import { Component, OnInit } from '@angular/core';
import { Paginator } from 'src/app/models/paginator.model';
import { JsonapiService, Filter } from 'src/app/services/jsonapi.service';
import { MessageService } from 'src/app/services/MessageService.service';
import { MatDialog } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { ErrorResponce } from 'src/app/models/jsonapi.model';

@Component({
  selector: 'app-clienti',
  templateUrl: './clienti.component.html',
  styleUrls: ['./clienti.component.scss']
})
export class ClientiComponent implements OnInit {
  public paginator: Paginator;
  public customers: Array<CustmerData>;
  public orderDirection = '';
  public orderField = 'rag_soc';
  public orderIcon = '';
  public recordPerPage = 10;
  public search = '';
  public loader = true;
  private readonly notifier: NotifierService;

  constructor(
    private jsonapiservice: JsonapiService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private notifierService: NotifierService,
  ) {
    this.notifier = notifierService;
  }
  ngOnInit() {
    this.getCustomers();
    this.messageService.filter({ event: 'selected_route', route: '/applicator/customers' });
    this.messageService.filter({ event: 'enable_menu' });
  }

  applyFilters() {
    const filters: Array<Filter> = [];
    if (this.search !== '') {
      filters.push({ name: 'search', value: this.search });
    }
    this.getCustomers(
      1,
      this.paginator['per-page'], filters);
  }

  getCustomers(page = 1, size = 10, filters?: Array<Filter>) {
    this.loader = true;
    const orderby = this.orderDirection + this.orderField;
    this.jsonapiservice.getCollectionPaginated('customers', page, size, filters, orderby)
      .subscribe((data: CustomerCollectionPaginated) => {
        this.paginator = data.meta.page;
        this.customers = data.data;
        this.loader = false;
        console.warn(this.paginator);
      }, (error: ErrorResponce) => { this.jsonapiservice.handlingError(error); });
  }

  orderBy(orderField) {
    if (this.orderField !== orderField) {
      this.orderField = orderField;
      this.orderDirection = '';
      this.orderIcon = 'fa-arrow-up';
    } else {
      this.orderDirection = (this.orderDirection === '-') ? '' : '-';
      this.orderIcon = (this.orderDirection === '-') ? 'fa-arrow-down' : 'fa-arrow-up';
    }
    this.getCustomers(this.paginator['current-page'], this.recordPerPage);
  }
  pageClick($event) {
    this.paginator = null;
    this.customers = null;
    this.getCustomers($event);
  }
  openDialog() {
    const dialogRef = this.dialog.open(NuovoClienteComponent, {
      height: 'auto',
      width: '500px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getCustomers(1, this.recordPerPage);
       /* if (result.length) {
         console.log('result : ', result);
        this.notifier.notify('success', 'Nuovo cliente inserito correttamente');
        this.getCustomers(1, this.recordPerPage);
       } */
    });
  }


}
