import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from './../../services/language.service';
import { appconfig as confen } from '../../config/order.config.en';
import { appconfig as confit } from '../../config/order.config.it';

@Component({
  selector: 'app-subscribe-expired',
  templateUrl: './subscribe-expired.component.html',
  styleUrls: ['./subscribe-expired.component.scss']
})
export class SubscribeExpiredComponent implements OnInit {
  mailto = '';

  constructor(
    private route: ActivatedRoute,
    private languageService: LanguageService
  ) { }

  ngOnInit() {
    const lang = this.languageService.getLang();
    if (!lang) {
      this.languageService.setInitialAppLanguage();
    }
    else {
      this.languageService.setLanguage(lang);
    }

    const account_mail = this.route.snapshot.paramMap.get('mail');

    if ('en' === this.languageService.getLang()) {
      const mail_renew = confen.mails.renew;
      this.mailto = "mailto:" + mail_renew.to;
      this.mailto += "?subject=" + mail_renew.subject;
      this.mailto += "&body=" + mail_renew.body_intro + account_mail + mail_renew.body_greetings;
    } else {
      const mail_renew = confit.mails.renew;
      this.mailto = "mailto:" + mail_renew.to;
      this.mailto += "?subject=" + mail_renew.subject;
      this.mailto += "&body=" + mail_renew.body_intro + account_mail + mail_renew.body_greetings;
    }
  }

}
