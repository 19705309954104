import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
@Injectable()
export class MessageService {

 /*  this.translateservice.get('i18n.errors.' + error.status.toString()).pipe(take(1))
  .subscribe((res: string) => {}); */
  private _listners = new Subject<any>();
  constructor(
    private translateservice: TranslateService
  ) {}

  translate(key: string) {
     return this.translateservice.get('i18n.' + key).pipe(take(1));
  }

    listen(): Observable<any> {
        return this._listners.asObservable();
    }
    filter(filterBy: object ) {
        this._listners.next(filterBy);
    }
    delete(event:string){    //the only thing that made sense to try so far
     /*  const FormList = this._listners.asObservable();

     // console.log(this._listners.observers.findIndex(a => a.event = event ));
      let target = this._listners.observers.findIndex(a => a.event = event );
      FormList.observers.unsubscribe(target); */
  }
}
