import { LanguageService } from './../../../services/language.service';
import { Entity } from 'src/app/models/jsonapi.model';
import { appconfig as confen } from '../../../config/order.config.en';
import { appconfig as confit } from '../../../config/order.config.it';
import { Data, CollectionPaginated, Collection, ErrorResponce } from './../../../models/jsonapi.model';
import { JsonapiService, Filter } from './../../../services/jsonapi.service';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/services/MessageService.service';
import { Paginator } from 'src/app/models/paginator.model';
import { Utilities } from 'src/app/common/utilities.class';
import { Route, Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user.model';
@Component({
  selector: 'app-ordini',
  templateUrl: './ordini.component.html',
  styleUrls: ['./ordini.component.scss']
})
export class OrdiniComponent implements OnInit {
  public paginator: Paginator;
  public orders: Array<Data>;
  public orderDirection = '';
  public orderField = '';
  public orderIcon = '';
  public recordPerPage = 10;
  public search = '';
  public summary = {errors : 0, documents : 0, customers: 0};
  public ordersStatuses; // = appconfig.orders.statuses;
  public filterState = '';
  public inError = false;
  public applicatorId = 0;
  public applicator   = null;
  public statuses; // appconfig.orders.selectStatus;
  public loader = false;
  public customerId = null;
  constructor(
      private messageService: MessageService,
      private jsonapiservice: JsonapiService,
      private router: Router,
      private route: ActivatedRoute,
      private auth: AuthService,
      private languageservice: LanguageService
    ) {}

  ngOnInit() {
    const lang = this.languageservice.getLang();
    // console.log(lang);
    if (lang === 'en') {
      this.ordersStatuses = confen.orders.statuses;
      this.statuses = confen.orders.selectStatus;

    } else {
      this.ordersStatuses = confit.orders.statuses;
      this.statuses = confit.orders.selectStatus;
    }


    this.getTotals();
    this.messageService.filter({ event: 'selected_route', route: '/shops/ordini' });
    this.messageService.filter({ event: 'enable_menu' });
    this.messageService.listen().subscribe((m: any) => {
      if (m.event === 'socket_message') {
        this.getTotals();
        this.getOrders(1);
      }
    });

    this.route.queryParams.subscribe(params => {
      this.applicatorId = params.applicator_id;
      const filters: Array<Filter> = [];
      if (this.applicatorId) {
        filters.push({ name: 'applicator_id', value: this.applicatorId });
        this.getApplicator();
      }
      const user = new User(this.auth.getLoggedUser());
      filters.push({name: 'shop_id', value: user.getRefId()});

      this.getOrders(
        1,
        this.recordPerPage, filters);
      this.orderBy('updated-at');
    });
  }

  getApplicator() {
    this.applicator = null;
    this.jsonapiservice.getEntity('applicators', this.applicatorId).subscribe((r: Entity) => {
      this.applicator = r.data.attributes;
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    });
  }

  showInErrors() {
    this.inError =  !this.inError;
    this.applyFilters();
  }

  getFilters() {
    const filters: Array<Filter> = [];
    const user = new User(this.auth.getLoggedUser());
    if (this.applicatorId) {
      filters.push({ name: 'applicator_id', value: this.applicatorId });
    }
    if (this.inError) {
      filters.push({ name: 'in_error', value: 1 });
    }

    if (this.filterState !== '' ) {
      filters.push({ name: 'last_mail_status', value: this.filterState });
    }

    if (this.search !== '') {
      filters.push({ name: 'search', value: this.search });
    }

    filters.push({name: 'shop_id', value: user.getRefId()});

    return filters;
  }

  applyFilters() {
    const filters = this.getFilters();
    this.getOrders(1, this.recordPerPage, filters);
  }

  getOrders(page = 1, size = 10, filters?: Array<Filter>) {

    this.loader = true;
    const orderby = this.orderDirection + this.orderField;
    this.jsonapiservice.getCollectionPaginated('order-view', page, size, filters, orderby)
      .subscribe((data: CollectionPaginated) => {
        this.paginator = data.meta.page;
        this.orders = data.data;
        this.loader = false;
        // console.log(this.orders);
      }, (error: ErrorResponce) => {
        this.jsonapiservice.handlingError(error);
        this.loader = false;
      });
  }

  getTotals() {
    this.jsonapiservice.customGetRequest('orders/summary/all').subscribe((data: any) => {
     this.summary.errors = data.attributes.errors;
     this.summary.documents = data.attributes.documents;
     this.summary.customers = data.attributes.customers;
    });
  }

  orderBy(orderField) {
    if (this.orderField !== orderField) {
      this.orderField = orderField;
      this.orderDirection = '';
      this.orderIcon = 'fa-arrow-up';
    } else {
      this.orderDirection = (this.orderDirection === '-') ? '' : '-';
      this.orderIcon = (this.orderDirection === '-') ? 'fa-arrow-down' : 'fa-arrow-up';
    }
    const current_page = (this.paginator && this.paginator['current-page']) ? this.paginator['current-page'] : 1 ;
    this.getOrders(current_page, this.recordPerPage);
  }

  pageClick($event) {
    this.paginator = null;
    this.orders = null;
    const filters = this.getFilters();
    this.getOrders($event, this.recordPerPage, filters);
  }

  resetFilters() {
    this.applicatorId = null;
    this.applicator = null;
    this.router.navigate(['/']);
  }

}
