import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-product-suggest',
  templateUrl: './product-suggest.component.html',
  styleUrls: ['./product-suggest.component.scss']
})
export class ProductSuggestComponent implements OnInit {

  formGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    manifacturer: new FormControl('', [Validators.required]),
    sku: new FormControl(''),
    negozio: new FormControl(''),
  });
  constructor() { }

  ngOnInit() {
  }

  save() {

  }

}
