import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss']
})
export class EditPasswordComponent implements OnInit {
  public emailAddress = '';
  private readonly notifier: NotifierService;

  constructor(
    private jsonapiservice: JsonapiService,
    private notifierService: NotifierService,
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {

  }

  requestPassword() {
    const API_BASE: string = environment[environment.env].base_url + '/auth/reset-password-request';
    this.jsonapiservice.resetPassword(API_BASE, this.emailAddress).subscribe((data) => {
      this.jsonapiservice.translate('i18n.editpassword.checkemail').subscribe(translation => {
        this.notifier.notify('success', translation);
        window.location.href = '/login';
      })
    });
  }

}
