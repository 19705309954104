import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private myRoute: Router,
    private auth: AuthService) { }

  ngOnInit() {
    const token = this.auth.getToken();
    this.logout(token);
  }

  logout(token) {
    this.auth.logout(token);
    window.location.href = '/login';
  }

}
