import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/services/MessageService.service';
import { Paginator } from 'src/app/models/paginator.model';
import { ProductEntity, ProductCollectionPaginated } from 'src/app/models/product.model';
import { NotifierService } from 'angular-notifier';
import { JsonapiService, Filter } from 'src/app/services/jsonapi.service';
import { MatDialog } from '@angular/material';
import { DownloadService } from 'src/app/services/Download.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Collection, ErrorResponce } from 'src/app/models/jsonapi.model';

@Component({
  selector: 'app-prodotti',
  templateUrl: './prodotti.component.html',
  styleUrls: ['./prodotti.component.scss']
})
export class ProdottiComponent implements OnInit {
  public paginator: Paginator;
  public products: Array<ProductEntity>;
  public orderDirection = '';
  public orderField = 'name';
  public orderIcon = '';
  public recordPerPage = 10;
  public manufacturers = null;
  public filterManufacturer = 0;
  public search = '';
  public loader = false;
  private readonly notifier: NotifierService;
  constructor(
    private jsonapiservice: JsonapiService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private notifierService: NotifierService,
    private downloadservice: DownloadService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.notifier = notifierService;
  }
  ngOnInit() {
    this.messageService.filter({ event: 'selected_route', route: '/shops/prodotti' });
    this.messageService.filter({ event: 'enable_menu' });
    this.getProducts();
    this.getManufacturers();
  }


  private getManufacturers() {
    this.manufacturers = [{ id: 0, name: 'Tutti' }];
    this.jsonapiservice.getCollection('manufacturers', null, 'rag_soc').subscribe((data: Collection) => {
      data.data.forEach((item) => {
        this.manufacturers.push({ id: item.id, name: item.attributes.rag_soc });
      });
    },(error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    });
  }

  applyFilters(page = 1) {
    const filters: Array<Filter> = [];
    if (this.filterManufacturer > 0) {
      filters.push({ name: 'manufacturer_id', value: this.filterManufacturer.toString() });
    }
    if (this.search !== '') {
      filters.push({ name: 'search', value: this.search });
    }
    this.getProducts(
      page,
      this.recordPerPage, filters);
  }



  getProducts(page = 1, size = 10, filters?: Array<Filter>) {
    if (this.loader) {
      return;
    }
    this.loader = true;
    const orderby = this.orderDirection + this.orderField;
    this.jsonapiservice.getCollectionPaginated('products', page, size, filters, orderby)
      .subscribe((data: ProductCollectionPaginated) => {
        this.paginator = data.meta.page;
        this.products = data.data;
        this.loader = false;
      }, (error: ErrorResponce) => this.jsonapiservice.handlingError(error));
  }

  orderBy(orderField) {
    if (this.orderField !== orderField) {
      this.orderField = orderField;
      this.orderDirection = '';
      this.orderIcon = 'fa-arrow-up';
    } else {
      this.orderDirection = (this.orderDirection === '-') ? '' : '-';
      this.orderIcon = (this.orderDirection === '-') ? 'fa-arrow-down' : 'fa-arrow-up';
    }
    this.getProducts(this.paginator['current-page'], this.recordPerPage);
  }


  pageClick($event) {
    this.paginator = null;
    this.products = null;
    this.applyFilters($event);
  }

  changeStyle($event) {
    const row = document.getElementById(`row-${$event.target.id}`);

    if (row) {
      if ($event.type === 'mouseover' && row.getAttribute('class') === 'moreinfo') {
        row.setAttribute('class', 'moreinfo active');
      }
      if ($event.type === 'click' ) {
        row.setAttribute('class', 'moreinfo show');
      }

      if ($event.type === 'mouseout' &&  row.getAttribute('class') === 'moreinfo active' ) {
        row.setAttribute('class', 'moreinfo');
      }
    }
  }

  public download(productId, lang = 'it') {
    this.downloadservice.setValue('download', { products : [productId]});
    const newWindow = window.open('download');
  }



}
