import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from 'src/app/services/MessageService.service';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { User } from 'src/app/models/user.model';
import * as moment from 'moment';

@Component({
  selector: 'app-richiesta',
  templateUrl: './richiesta.component.html',
  styleUrls: ['./richiesta.component.scss']
})
export class RichiestaComponent implements OnInit {
  public section = '';
  private readonly notifier: NotifierService;
  public sended = false;
  public translatedRes;
  @Input() set newRequest(val: boolean) {
    this.sended = !val;
  }

  @Output() hasSended  = new EventEmitter();

  constructor(
    private auth: AuthService,
    private messageService: MessageService,
    private jsonapiservice: JsonapiService,
    private notifierService: NotifierService,
    ) {
      this.notifier = notifierService;
    }
  public FormGroup = new FormGroup({
    descrizione: new FormControl('' , [ Validators.required ]),
    tipologia:  new FormControl('' , [ Validators.required ]),
    soggetto: new FormControl('', [Validators.required])
  });
  ngOnInit() {
    this.messageService.filter({ event: 'selected_route',  route: '/supporto'});
    this.messageService.filter({ event: 'enable_menu'});

    this.jsonapiservice.translate('i18n.support').subscribe(res => {
      this.translatedRes = res;
    });

  }

  save() {
    const user = new User(this.auth.getLoggedUser());
    const data = {
      data: {
        type: 'ticket',
        attributes: {
          date_send: moment().format('YYYY-MM-DD'),
          email: user.getEmail(),
          shop_id :  (user.isShop()) ? user.getRefId() : null,
          applicator_id: (user.isApplicator()) ? user.getRefId() : null,
          user_id : user.getId(),
          soggetto: this.FormGroup.value.soggetto,
          tipologia : this.FormGroup.value.tipologia,
          descrizione : this.FormGroup.value.descrizione
        }
      }
    };

    this.jsonapiservice.postEntity('ticket', data).subscribe((resp) => {
      this.notifier.notify('success', this.translatedRes.requestsended);
      this.sended = true;
      this.hasSended.emit(true);
    }, (error) => {
      this.jsonapiservice.handlingError(error);
    });
  }

}
