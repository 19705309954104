import { CollectionPaginated, ErrorResponce } from './../../../models/jsonapi.model';
import { Component, OnInit } from '@angular/core';
import { Paginator } from 'src/app/models/paginator.model';
import { CustmerData, CustomerCollectionPaginated } from 'src/app/models/customer.model';
import { NotifierService } from 'angular-notifier';
import { JsonapiService, Filter } from 'src/app/services/jsonapi.service';
import { MessageService } from 'src/app/services/MessageService.service';
import { MatDialog } from '@angular/material';
import { NuovoClienteComponent } from '../../applicators/clienti/nuovo-cliente/nuovo-cliente.component';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user.model';
import { appconfig as confen } from '../../../config/order.config.en';
import { appconfig as confit } from '../../../config/order.config.it';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'app-clienti',
  templateUrl: './clienti.component.html',
  styleUrls: ['./clienti.component.scss']
})
export class ClientiComponent implements OnInit {
  public paginator: Paginator;
  public customers: Array<CustmerData>;
  public orderDirection = '';
  public orderField = 'rag_soc';
  public orderIcon = '';
  public recordPerPage = 10;
  public search = '';
  public loader = true;
  private readonly notifier: NotifierService;
  public ordersStatuses;
  private shopId = null;
  public statuses; // appconfig.orders.selectStatus;

  constructor(
    private jsonapiservice: JsonapiService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private notifierService: NotifierService,
    private auth: AuthService,
    private languageservice: LanguageService
  ) {
    this.notifier = notifierService;
  }
  ngOnInit() {
    const lang = this.languageservice.getLang();
    console.log(lang);
    if (lang === 'en') {
      this.ordersStatuses = confen.orders.statuses;
      this.statuses = confen.orders.selectStatus;

    } else {
      this.ordersStatuses = confit.orders.statuses;
      this.statuses = confit.orders.selectStatus;
    }


    this.messageService.filter({ event: 'selected_route', route: '/shops/clienti' });
    this.messageService.filter({ event: 'enable_menu' });
    const user = new User(this.auth.getLoggedUser());
    const filters: Array<Filter> = [];
    this.shopId = user.getRefId();
    filters.push({ name: 'shop_id', value: this.shopId });
    this.getCustomers(1,  this.recordPerPage, filters);
  }

  applyFilters() {
    const filters: Array<Filter> = [];
    if (this.search !== '') {
      filters.push({ name: 'search', value: this.search });
    }
    filters.push({ name: 'shop_id', value: this.shopId });
    this.getCustomers(
      1,
      this.paginator['per-page'], filters);
  }

  getCustomers(page = 1, size = 10, filters?: Array<Filter>) {
    this.loader = true;
    const orderby = this.orderDirection + this.orderField;
    this.jsonapiservice.getCollectionPaginated('applicators-view', page, size, filters, orderby)
      .subscribe((data: CollectionPaginated) => {
        this.paginator = data.meta.page;
        this.customers = data.data;
        this.loader = false;
      }, (error: ErrorResponce) => {
        this.jsonapiservice.handlingError(error);
      });
  }

  orderBy(orderField) {
    if (this.orderField !== orderField) {
      this.orderField = orderField;
      this.orderDirection = '';
      this.orderIcon = 'fa-arrow-up';
    } else {
      this.orderDirection = (this.orderDirection === '-') ? '' : '-';
      this.orderIcon = (this.orderDirection === '-') ? 'fa-arrow-down' : 'fa-arrow-up';
    }
    this.getCustomers(this.paginator['current-page'], this.recordPerPage);
  }

  /**
   * pageClick
   * @param $event
   */
  pageClick($event) {
    this.paginator = null;
    this.customers = null;
    this.getCustomers($event);
  }
  /* openDialog() {
    const dialogRef = this.dialog.open(NuovoClienteComponent, {
      height: 'auto',
      width: '500px',
    });
    dialogRef.afterClosed().subscribe((result: Array<string>) => {
       if (result.length) {
        this.notifier.notify('success', 'Nuovo cliente inserito correttamente');
        this.getCustomers(1, this.recordPerPage);
       }
    });
  } */


}

