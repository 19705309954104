export const appconfig = {
  orders : {
    selectStatus : [
      { value : '', text : 'Tutti'},
      { value : 'ACCETTAZIONE', text : 'Inviato'},
      { value : 'CONSEGNA', text : 'Consegnato'},
      { value : 'AVVISO DI MANCATA CONSEGNA', text : 'Indirizzo email inesistente'},
      { value : 'NO PEC', text : 'PEC inesistente'},
      { value : 'NOT SENDEND' , text : 'Documentazione incompleta'}
    ],
    statuses : {
       'INVIATA' : {
        class : '',
        text: `Inviato`,
        error: false,
      },
      'ACCETTAZIONE' : {
        class : '',
        text: `Inviato`,
        error: false,
      },
      'CONSEGNA' : {
        class : 'greentxt',
        text: `Consegnato`,
        error: false,
      },
      'AVVISO DI MANCATA CONSEGNA' : {
        class : 'redtxt',
        text: `Indirizzo email inesistente`,
        error: true,
      },
      'NO PEC' : {
        class : 'redtxt',
        text: `PEC inesistente`,
        error: true,
      },
      'NOT SENDEND' : {
        class : 'redtxt',
        text: `Documentazione incompleta`,
        error: true,
      },
      'MISSING MANUFACTURER' : {
        class : 'redtxt',
        text: `Produttore mancante`,
        error: true,
      },
      'MISSING MANUFACTURER EMAIL' : {
        class : 'redtxt',
        text: `Email produttore mancante`,
        error: true,
      },
    }
  },
  mails: {
    renew: {
      to: "miscali@netseven.it",
      subject: "Richiesta%20di%20rinnovo%20-%20Licenza%20SafeUp",
      body_intro: "Gentile%20operatore,%0d%20chiedo%20il%20rinnovo%20annuale%20della%20licenza%20SafeUp%20per%20l'account%20",
      body_greetings: "%0d%0dSaluti"
    }
  }
};
