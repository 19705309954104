import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from 'src/app/services/MessageService.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  constructor(
    private oauth: AuthService,
    private messageservice: MessageService
  ) { }
  public className = '';
  public user: User = null;

  ngOnInit() {
    this.user = new User(this.oauth.getLoggedUser());
    this.messageservice.filter({ event: 'enable_menu' });
    this.messageservice.filter({ event: 'selected_route', route: '/' });
  }
}
