import { ProductEntity } from './../../../../models/product.model';
import { Component, OnInit } from '@angular/core';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { MessageService } from 'src/app/services/MessageService.service';
import { ActivatedRoute } from '@angular/router';
import { DownloadService } from 'src/app/services/Download.service';
import { ErrorResponce } from 'src/app/models/jsonapi.model';

@Component({
  selector: 'app-scheda-prodotto',
  templateUrl: './scheda-prodotto.component.html',
  styleUrls: ['./scheda-prodotto.component.scss']
})
export class SchedaProdottoComponent implements OnInit {
  public product: ProductEntity = null;
  public files = [];
  constructor(
    private jsonapiservice: JsonapiService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private downloadservice: DownloadService
  ) { }

  ngOnInit() {
    const productId = this.route.snapshot.paramMap.get('id');
    this.getProduct(productId);
  }

  public getProduct(productId) {
    this.jsonapiservice.getEntity('products', productId).subscribe((product: any) => {
      this.product = product.data;
      this.product.meta.docs.forEach((file) => {
        if (!this.files[file["lang_code"]]) {
          this.files[file["lang_code"]] = [];
        }
        this.files[file["lang_code"]].push(file);
      });
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    });
  }

  public download(productId, language, kind) {
    this.downloadservice.setValue('download', { products: [productId], lang: language, subtype: kind });
    const newWindow = window.open('download');
  }
}
