import { SendEmailComponent } from './../../../../components/send-email/send-email.component';
import { Component, OnInit } from '@angular/core';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { MessageService } from 'src/app/services/MessageService.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Entity, ErrorResponce } from 'src/app/models/jsonapi.model';
import { MatDialog } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { DownloadService } from 'src/app/services/Download.service';

@Component({
  selector: 'app-scheda-ordine',
  templateUrl: './scheda-ordine.component.html',
  styleUrls: ['./scheda-ordine.component.scss']
})
export class SchedaOrdineComponent implements OnInit {
  public ordine = null;
  public loader = true;
  public inError = false;
  public pecHistory = null;
  public msds = { success: [], error: [] };
  public sendmenu = false;
  public resultEmail = { date: new Date(), email: ''};
  public translateRes;
  public productsWithFile = [];

  private readonly notifier: NotifierService;
  constructor(
    private jsonapiservice: JsonapiService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private notifierService: NotifierService,
    private downloadservice: DownloadService
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    const orderId = this.route.snapshot.paramMap.get('id');
    this.jsonapiservice.translate('i18n.shop.order').subscribe(res => {
      this.translateRes = res;
      this.messageService.filter({ event: 'selected_route', route: '/shops/ordini' });
      this.messageService.filter({ event: 'enable_menu' });
      this.getEntity(orderId);
      this.getHistoryPec(orderId);
    });
  //  this.messageService
    this.messageService.listen().subscribe((m: any) => {
    if (m.event === 'socket_message') {
      this.getEntity(orderId);
      this.getHistoryPec(orderId);
    }
    });
  }

  public getEntity(id) {
    this.jsonapiservice.getEntity('order-view', id).subscribe((ordine: any) => {
      this.ordine = ordine;
      this.msds.success = this.ordine.data.attributes.msds_sended;
      this.msds.error = this.ordine.data.attributes.msds_not_sended;

      this.msds.success.forEach(element => {
        this.productsWithFile.push(Number(element.id));
      });

    // TODO: questi sono i prodotti che non hanno i file, che si fa?
    // this.msds.error.forEach(element => {
    //   this.productsWithFile.push(Number(element.id));
    // });

      if (Number(ordine.data.attributes.in_error)) {
        this.inError = true;
      } else {
        this.inError = false;
      }
      this.loader = false;
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    });
  }

  public getHistoryPec(id) {
    this.jsonapiservice.customGetRequest(`orders/pecs/${id}`).subscribe((data: any) => {
      this.pecHistory = data;
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    });
  }

  public downloadDocumentation(){
    const sproducts = [];

    this.downloadservice.setValue('download', { products: this.productsWithFile });
    const newWindow = window.open('download');
  }

  public hasProductDocumentation(element){
    const index = this.productsWithFile.indexOf(element.id);
    if (index < 0) {
      return false;
    }
    return true;
  }

  public downloadProductDocumentation(element){
    if (this.hasProductDocumentation(element)) {
      this.downloadservice.setValue('download', { products: [element.id] });
      const newWindow = window.open('download');
    }
  }

  public viewCustomerOrders(applicatorID) {
    this.router.navigateByUrl('/shops/ordini?applicator_id=' + applicatorID);
  }


  public resolve() {
    const orderId = this.route.snapshot.paramMap.get('id');
    const data = {
      data:
      {
        id: orderId.toString(),
        type: 'order',
        attributes: {
          in_error: 0,
      //    last_mail_status: 'SOLVED'
        }
      }
    };
    this.jsonapiservice.updateEntity('order', orderId, data).subscribe((responce: any) => {
      this.getEntity(orderId);
      this.getHistoryPec(orderId);
      this.notifier.notify('success', this.translateRes.errorSolved);
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    });
  }

  send(alternativeEmail = false) {
    this.sendmenu = false;
    const orderId = this.route.snapshot.paramMap.get('id');
    if (alternativeEmail) {

      const dialogRef = this.dialog.open(SendEmailComponent, {
        height: 'auto',
        width: '500px',
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result.email) {
          const postData = {
            data:
            {
              type: 'orders',
              attributes: {
                email : result.email
              }
            }
          };
          this.resultEmail.email = result.email;
          this.sendDocs(postData);
        }
      });

    } else {
      const postData = {
        data:
        {
          type: 'orders',
          attributes: {}
        }
      };
      this.resultEmail.email = this.ordine.data.attributes.applicator_pec;
      this.sendDocs(postData);
    }
  }

  sendDocs(postData) {
    const orderId = this.route.snapshot.paramMap.get('id');
    this.jsonapiservice.postEntityCustomPath(postData, `orders/sendpec/${orderId}`).subscribe((r: any) => {
      if (r.data.id) {
          this.getEntity(orderId);
          this.getHistoryPec(orderId);
          this.notifier.notify('success', this.translateRes.documentsended);
      }
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
     // this.notifier.notify('error', 'Si è verificato un errore sul server');
    });
  }

  toggleMenu() {
    this.sendmenu = !this.sendmenu;
  }


}
