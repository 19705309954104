import { JsonapiService } from './../services/jsonapi.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'when' })
export class When implements PipeTransform {

public translatedresource = null;

constructor(private jsonapiservice: JsonapiService ) {
  }

    transform(value: string, date: string): any {
      const d1 = new Date(value);
      const d2 = new Date(date);
      const difference_In_Time = d2.getTime() - d1.getTime();
      const difference_In_Minutes = difference_In_Time / (1000 * 60);
      const difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
      const difference_In_Month= ( difference_In_Time / (1000 * 3600 * 24) ) / 30;

      this.jsonapiservice.translate('i18n.times').subscribe((res: any) => {
        this.translatedresource = res;
        if (difference_In_Minutes < 1 ) {
          return this.translatedresource.minutes;
        }

        if (difference_In_Days < 1 ) {
          return this.translatedresource.today;
        }
        if (difference_In_Days === 1 ) {
          return this.translatedresource.yesterday;
        }

        if (difference_In_Days > 30) {
          return Math.ceil(difference_In_Month) + this.translatedresource.months;
        }
        return Math.ceil(difference_In_Days) + ' ' + this.translatedresource.days;


      });


    }

}
