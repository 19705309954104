import { ErrorResponce } from 'src/app/models/jsonapi.model';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { AuthService } from 'src/app/services/auth.service';
import { LanguageService } from './../../services/language.service';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-langselector',
  templateUrl: './langselector.component.html',
  styleUrls: ['./langselector.component.scss']
})
export class LangselectorComponent implements OnInit {

  constructor(
    private languageservice: LanguageService,
    private auth: AuthService,
    private jsonapiservice: JsonapiService) { }

  public lang;
  ngOnInit() {
    this.lang = this.languageservice.getLang();
  }
  public setLang(language) {
    const user = new User(this.auth.getLoggedUser());
    this.languageservice.setLanguage(user.getLang());
    this.languageservice.setLanguage(language);
    const id = user.getId();
    const updateData = {
      data: {
        type: 'users',
        id: `${id}`,
        attributes: {
          lang: language
        }
      }
    };
    this.jsonapiservice.updateEntity('users', user.getId(), updateData)
    .subscribe((r) => {
      console.log(r);
    }, (error: ErrorResponce) => { this.jsonapiservice.handlingError(error); });
  }

}
