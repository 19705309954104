import { LanguageService } from './../../../services/language.service';
import { Component, OnInit } from '@angular/core';
import { Entity, ErrorResponce } from './../../../models/jsonapi.model';
import { JsonapiService } from './../../../services/jsonapi.service';
import { AuthService } from './../../../services/auth.service';
import { User, IUser } from './../../../models/user.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { NotifierService } from 'angular-notifier';
import { LocalJsonService } from 'src/app/services/local.json.service';

@Component({
  selector: 'app-profilo',
  templateUrl: './profilo.component.html',
  styleUrls: ['./profilo.component.scss']
})


export class ProfiloComponent implements OnInit {
  private readonly notifier: NotifierService;
  public User: User;
  public Applicator: Entity = null;
  public province: Array<any>;
  applicatorFromGroup = new FormGroup({
    rag_soc: new FormControl(),
    comune: new FormControl(),
    pec: new FormControl('', [
      Validators.required,
      Validators.pattern("[^ @]*@[^ @]*")
    ]),
    email: new FormControl('', [
      Validators.pattern("[^ @]*@[^ @]*")
    ]),
    indirizzo: new FormControl(),
    provincia: new FormControl(),
    cap: new FormControl(),
    ncivico: new FormControl(),
    piva: new FormControl('', [
      Validators.required,
      Validators.pattern('^([0-9]{11})|([A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1})$')
    ]),
  });
  public lang: string;

  constructor(
    private auth: AuthService,
    private jsonapiservice: JsonapiService,
    private localjsonservice: LocalJsonService,
    private notifierService: NotifierService,
    private languageservice: LanguageService
  ) {
    this.notifier = notifierService;
   }

  ngOnInit() {
    this.lang = this.languageservice.getLang();
    this.auth.getUser().subscribe((user: IUser) => {
      this.User = new User(user);

      // ricerca applicatore
      this.localjsonservice.getLocalJson('province.json').subscribe((s: Array<any>) => {
        this.province = s;
      }, (error: ErrorResponce) => {
        this.jsonapiservice.handlingError(error);
      });


      this.jsonapiservice.getEntity('applicators',
        this.User.data.attributes.ref_id)
        .subscribe((entity: Entity) => {
          this.Applicator = entity;
          this.applicatorFromGroup.controls.rag_soc.setValue(this.Applicator.data.attributes.rag_soc);
          this.applicatorFromGroup.controls.comune.setValue(this.Applicator.data.attributes.comune);
          this.applicatorFromGroup.controls.pec.setValue(this.Applicator.data.attributes.pec);
          this.applicatorFromGroup.controls.email.setValue(this.Applicator.data.attributes.email);
          this.applicatorFromGroup.controls.piva.setValue(this.Applicator.data.attributes.piva);
          this.applicatorFromGroup.controls.indirizzo.setValue(this.Applicator.data.attributes.indirizzo);
          this.applicatorFromGroup.controls.provincia.setValue(this.Applicator.data.attributes.provincia);
          this.applicatorFromGroup.controls.cap.setValue(this.Applicator.data.attributes.cap);
          this.applicatorFromGroup.controls.ncivico.setValue(this.Applicator.data.attributes.ncivico);
        //  this.applicatorFromGroup.controls.note.setValue(this.Applicator.data.attributes.note);
        }, (error: ErrorResponce) => {
          this.jsonapiservice.handlingError(error);
        });
    });
  }

  public setLang(lang) {
   // this.languageservice.setLanguage(lang);
  }
  public save() {
    const updateData = {
      data: {
        type: this.Applicator.data.type,
        id: this.Applicator.data.id,
        attributes: {
          rag_soc: this.applicatorFromGroup.value.rag_soc,
          piva: this.applicatorFromGroup.value.piva,
          pec: this.applicatorFromGroup.value.pec,
          email: this.applicatorFromGroup.value.email,
          indirizzo: this.applicatorFromGroup.value.indirizzo,
          comune: this.applicatorFromGroup.value.comune,
          ncivico: this.applicatorFromGroup.value.ncivico,
          provincia: this.applicatorFromGroup.value.provincia,
          cap: this.applicatorFromGroup.value.cap,
      //    note: this.applicatorFromGroup.value.note
        }
      }
    };
    this.jsonapiservice.updateEntity(this.Applicator.data.type, this.Applicator.data.id, updateData).subscribe((resp) => {
       // this.notifier.notify('success', 'Profilo aggiornato correttamente');
       this.jsonapiservice.notify('success', 'i18n.common.profileadjurned');
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    });
  }

}
