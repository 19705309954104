export const appconfig = {
  orders : {
    selectStatus : [
      { value : '', text : 'All'},
      { value : 'ACCETTAZIONE', text : 'Sent'},
      { value : 'CONSEGNA', text : 'Delivered'},
      { value : 'AVVISO DI MANCATA CONSEGNA', text : 'Email address does not exist'},
      { value : 'NO PEC', text : 'PEC address does not exist'},
      { value : 'NOT SENDEND' , text : 'Incomplete documentation'}
    ],
    statuses : {
      'INVIATA' : {
        class : '',
        text: `Sent`,
        error: false,
      },
      'ACCETTAZIONE' : {
        class : '',
        text: `Sent`,
        error: false,
      },
      'CONSEGNA' : {
        class : 'greentxt',
        text: `Delivered`,
        error: false,
      },
      'AVVISO DI MANCATA CONSEGNA' : {
        class : 'redtxt',
        text: `Email address does not exist`,
        error: true,
      },
      'NO PEC' : {
        class : 'redtxt',
        text: `PEC address does not exist`,
        error: true,
      },
      'NOT SENDEND' : {
        class : 'redtxt',
        text: `Incomplete documentation`,
        error: true,
      },
      'MISSING MANUFACTURER' : {
        class : 'redtxt',
        text: `Missing manufacturer`,
        error: true,
      },
      'MISSING MANUFACTURER EMAIL' : {
        class : 'redtxt',
        text: `Missing manufacturer email`,
        error: true,
      },
    }
  },
  mails: {
    renew: {
      to: "miscali@netseven.it",
      subject: "Richiesta%20di%20rinnovo%20-%20Licenza%20SafeUp",
      body_intro: "Gentile%20operatore,%0d%20chiedo%20il%20rinnovo%20annuale%20della%20licenza%20SafeUp%20per%20l'account%20",
      body_greetings: "%0d%0dSaluti"
    }
  }
};
