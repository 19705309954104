import { TranslateService } from '@ngx-translate/core';
import { Collection, ErrorResponce } from './../../../../models/jsonapi.model';
import { Component, OnInit } from '@angular/core';
import { Data, Entity } from 'src/app/models/jsonapi.model';
import { JsonapiService, Filter } from 'src/app/services/jsonapi.service';
import { MessageService } from 'src/app/services/MessageService.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { MatDialog } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-scheda-produttore',
  templateUrl: './scheda-produttore.component.html',
  styleUrls: ['./scheda-produttore.component.scss']
})
export class SchedaProduttoreComponent implements OnInit {
  public entity: Data = null;
  private readonly notifier: NotifierService;
  private resource = 'manufacturers';
  public error = null;
  sdsImports = '';
  sdsEmails  = [];
  activeTab = 'anagrafica';
  FormGroup = new FormGroup({
    rag_soc: new FormControl(),
    pec: new FormControl('', [
      Validators.pattern("[^ @]*@[^ @]*")
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern("[^ @]*@[^ @]*")
    ]),
    piva: new FormControl('', [
      Validators.required,
      // Validators.pattern('^([0-9]{11})|([A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1})$')
    ])
  });
  constructor(
    private jsonapiservice: JsonapiService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private notifierService: NotifierService,
    public dialog: MatDialog,
    private translateService: TranslateService,
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.messageService.filter({ event: 'selected_route', route: '/admin/produttori' });
    this.messageService.filter({ event: 'enable_menu' });
    const entityId = this.route.snapshot.paramMap.get('id');
    this.getEntity(entityId);
    this.getSdsImports(entityId);
  }

  public getSdsImports(entityId) {
    const filters: Array<Filter> = [];
    filters.push({ name: 'manufacturer_id', value: entityId });
    this.jsonapiservice.getCollection('manufacturer-emails', filters).subscribe((r: Collection) => {
       const arr = [];
       this.sdsEmails = r.data;
       r.data.forEach(element => {
        arr.push(element.attributes.email);
       });
       this.sdsImports = arr.join(';');
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    });
  }

  public getEntity(entityId) {
    this.jsonapiservice.getEntity(this.resource, entityId).subscribe((entity: Entity) => {
      this.entity = entity.data;
      this.FormGroup.controls['rag_soc'].setValue(this.entity.attributes.rag_soc);
      this.FormGroup.controls['email'].setValue(this.entity.attributes.email);
      this.FormGroup.controls['pec'].setValue(this.entity.attributes.pec);
      this.FormGroup.controls['piva'].setValue(this.entity.attributes.piva);
    });
  }

  saveSdsEmails() {
    const entityId = this.route.snapshot.paramMap.get('id');
    const emails   = this.sdsImports.split(';');
    const data = {
      data: []
    };
    emails.forEach((item) => {
      data.data.push({ email: item});
    });
    this.jsonapiservice.postEntityCustomPath(data, `manufacturer-emails/insert/${entityId}`)
    .subscribe((r) => {
      this.notifier.notify('success', 'Emails/Domini SDS aggiornate correttamente');
    }, (err: ErrorResponce) => {
      this.jsonapiservice.handlingError(err);
    });
  }

  save() {
    const entityId = this.route.snapshot.paramMap.get('id');
    const updateData = {
      data: {
        type: this.resource,
        id: entityId,
        attributes: {
          rag_soc: this.FormGroup.value.rag_soc,
          piva: this.FormGroup.value.piva,
          pec: this.FormGroup.value.pec,
          email: this.FormGroup.value.email,
          note: this.FormGroup.value.note
        }
      }
    };
    this.jsonapiservice.updateEntity(this.resource, entityId, updateData).subscribe((resp) => {
      this.notifier.notify('success', 'Scheda produttore aggiornata correttamente');
      setTimeout(() => {
        this.router.navigateByUrl('/admin/produttori');
      }, 1000) ;
    }, (error: ErrorResponce) => {
        this.jsonapiservice.handlingError(error);
    });
  }

  public changeTab(tab: string) {
    this.activeTab = tab;
  }

}
