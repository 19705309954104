import { CollectionPaginated, Meta, Entity, ErrorResponce } from './../../../../models/jsonapi.model';
import { TicketEntity } from './../../../../models/tickets.model';
import { SocketService } from './../../../../services/socket.service';
import { Component, OnInit, Inject } from '@angular/core';
import { JsonapiService, Filter } from 'src/app/services/jsonapi.service';
import { Paginator } from 'src/app/models/paginator.model';
import { ProductCollectionPaginated } from 'src/app/models/product.model';
import * as moment from 'moment';

@Component({
  selector: 'app-riepilogo',
  templateUrl: './riepilogo.component.html',
  styleUrls: ['./riepilogo.component.scss']
})
export class RiepilogoComponent implements OnInit {
  private daysofnewsubscription = 90;
  public paginator: Paginator;
  public limit = 5;
  public riepilogo = {
    abbonamenti: { others: 0, total: 0 },
    rinnovi: { others: 0, total: 0 },
    prodotti: []
  };
  public orderDirection = '';
  public orderField = 'name';
  public orderIcon = '';
  public priorityClasses = [`priority-toDefine`, `priority-normal`, `priority-high`, `priority-critical`];
  public statusClasses = [`status-new`, `status-handled`, `status-resolved`];
  public statuses;
  public priorities;
  public ticketToLoad = false;
  public summary = {
    subscriptions: null,
    renewal: null,
    shops: null,
    documents: null,
    errors: null,
    customers: null,
  };
  public year = null;
  public activetab = 'abbonamenti';
  public tickets: Array<TicketEntity>;
  public allTickets = 0;
  public otherTickets = 0;

  constructor(
    private jsonapiservice: JsonapiService,
  ) { }

  ngOnInit() {
    const filters: Array<Filter> = [];
    filters.push({ name: 'todo', value: 1 });
    this.getTickets(filters);
    this.getSummary();
    this.year = moment().format('YYYY');

    this.jsonapiservice.translate('i18n.admin.tickets.statuses').subscribe((res: any) => {
      this.statuses = res;
    });
    this.jsonapiservice.translate('i18n.admin.tickets.priorities').subscribe((res: any) => {
      this.priorities = res;
    });
  }

  orderBy(orderField) {
    if (this.orderField !== orderField) {
      this.orderField = orderField;
      this.orderDirection = '';
      this.orderIcon = 'fa-arrow-up';
    } else {
      this.orderDirection = (this.orderDirection === '-') ? '' : '-';
      this.orderIcon = (this.orderDirection === '-') ? 'fa-arrow-down' : 'fa-arrow-up';
    }
    const filters: Array<Filter> = [];
    filters.push({ name: 'todo', value: 1 });
    this.getTickets(filters, this.orderDirection + this.orderField);
  }
  getSummary() {
    this.jsonapiservice.getEntity('summary', 'all').subscribe((resp: any) => {
      this.summary = resp.attributes;
    }, (error: ErrorResponce) => this.jsonapiservice.handlingError(error));
  }
  getTickets(filters?: Array<Filter>, orderby?: string) {
    this.ticketToLoad = false;
    this.tickets = [];
    this.jsonapiservice.getCollectionPaginated('ticket', 1, 10, filters, orderby)
      .subscribe((responce: CollectionPaginated) => {
        this.allTickets = responce.meta.page.total;
        this.tickets = responce.data;
        this.otherTickets = this.allTickets - 10;
        this.ticketToLoad = true;
      }, (error: ErrorResponce) => {
        this.ticketToLoad = true;
        this.jsonapiservice.handlingError(error);
      });
  }
  /*
    articoliSenzaDocumenti(page = 1, size = 5) {
      this.riepilogo.prodotti = [];
      const filters: Array<Filter> = [];
      filters.push({ name: 'nodocs', value: true });
      this.jsonapiservice.getCollectionPaginated('products', page, size, filters)
        .subscribe((data: ProductCollectionPaginated) => {
          this.paginator = data.meta.page;
          this.riepilogo.prodotti = data.data;
        }, (error) => console.error(error));
    }

  /*
    newSubscriptions() {
      const filters: Array<Filter> = [];
      filters.push({ name: 'abbonamenti', value: this.daysofnewsubscription });
      this.jsonapiservice.getCollection('applicators', filters)
          .subscribe((data: Collection) => {
            this.riepilogo.abbonamenti.total = data.data.length;
            if (data.data.length > this.limit) {
              this.riepilogo.abbonamenti.others = data.data.length - this.limit;
            }
            this.recordset.abbonamenti = data.data;
            console.log(this.recordset.abbonamenti);
          }, (error) => console.error(error));
    }
  /*
    rinnovi() {
      const filters: Array<Filter> = [];
      filters.push({ name: 'scadenza', value: 30 });
      this.jsonapiservice.getCollection('applicators', filters)
          .subscribe((data: Collection) => {
            this.riepilogo.rinnovi.total = data.data.length;
            if (data.data.length > this.limit) {
              this.riepilogo.rinnovi.others = data.data.length - this.limit;
            }
            this.recordset.rinnovi = data.data;
          }, (error) => console.error(error));
    }
  */
  changeTab(tab) {
    this.activetab = tab;
  }

  pageClick($event) {
    /*this.paginator = null;
    this.riepilogo.prodotti = [];
    this.articoliSenzaDocumenti($event);*/
  }

  gotoTickets() {

  }
}
