import { NuovoProduttoreComponent } from './nuovo-produttore/nuovo-produttore.component';
import { CollectionPaginated, ErrorResponce } from './../../../models/jsonapi.model';
import { DownloadService } from '../../../services/Download.service';
import { MessageService } from './../../../services/MessageService.service';
import { JsonapiService, Filter } from './../../../services/jsonapi.service';
import { Paginator } from './../../../models/paginator.model';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { Collection, Entity } from 'src/app/models/jsonapi.model';
import { ProductsSearchDialogComponent } from 'src/app/components/products-search-dialog/products-search-dialog.component';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';

interface ImportedResponce {
  data: Data;
}

interface Data {
  type: string;
  attributes: Attributes;
}

interface Attributes {
  imported: number;
  exists: number;
}
@Component({
  selector: 'app-produttori',
  templateUrl: './produttori.component.html',
  styleUrls: ['./produttori.component.scss']
})
export class ProduttoriComponent implements OnInit {
  public loading = false;
  public paginator: Paginator;
  public manufacturers: Array<any>;
  public orderDirection = '';
  public orderField = 'rag_soc';
  public orderIcon = '';
  public recordPerPage = 10;
  public filterManufacturer = 0;
  public search = '';
  public selectedItem = null;
  private readonly notifier: NotifierService;
  public translatedResources;
  constructor(
    private jsonapiservice: JsonapiService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private notifierService: NotifierService,
    private router: Router,
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.jsonapiservice.translate('i18n.admin.manufacturer').subscribe((res: any) => {
      this.translatedResources = res;
      this.getManufacturers();
    });
    this.messageService.filter({ event: 'selected_route', route: '/admin/produttori' });
    this.messageService.filter({ event: 'enable_menu' });
  }

  getManufacturers(page = 1, size = 10, filters?: Array<Filter>) {
    this.loading = true;
    this.manufacturers = [];
    const orderby = this.orderDirection + this.orderField;
    this.jsonapiservice.getCollectionPaginated('manufacturers', page, size, filters, orderby)
      .subscribe((data: CollectionPaginated) => {
        this.paginator = data.meta.page;
        this.manufacturers = data.data;
        this.loading = false;
      }, (error: ErrorResponce) => this.jsonapiservice.handlingError(error));
  }

  applyFilters() {
    const filters: Array<Filter> = [];
    if (this.filterManufacturer > 0) {
      filters.push({ name: 'manufacturer_id', value: this.filterManufacturer.toString() });
    }
    if (this.search !== '') {
      filters.push({ name: 'search', value: this.search });
    }
    this.getManufacturers(
      1,
      this.paginator['per-page'], filters);
  }

  showProducts(manufacturerId) {
    this.router.navigateByUrl(`/admin/prodotti/produttore/${manufacturerId}`);
  }

  orderBy(orderField) {
    if (this.orderField !== orderField) {
      this.orderField = orderField;
      this.orderDirection = '';
      this.orderIcon = 'fa-arrow-up';
    } else {
      this.orderDirection = (this.orderDirection === '-') ? '' : '-';
      this.orderIcon = (this.orderDirection === '-') ? 'fa-arrow-down' : 'fa-arrow-up';
    }
    this.getManufacturers(this.paginator['current-page'], this.recordPerPage);
  }


  pageClick($event) {
    this.paginator = null;
    this.manufacturers = null;
    this.getManufacturers($event);
  }

  openDialog() {
    const dialogRef = this.dialog.open(ProductsSearchDialogComponent, {
      data: {
        search: this.search,
        manufacturer: this.filterManufacturer
      },
      height: '400px',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe((result: Array<string>) => {
      const payload = {
        data:
        {
          type: 'products',
          attributes:
          {
            products: result
          }
        }
      };
      this.jsonapiservice.postEntity('products', payload, 'import')
      .subscribe((resp: ImportedResponce) => {
        /**
         * @todo implementare multilinguage
         */
        let message =  resp.data.attributes.imported.toString() + this.translatedResources.productimported;
        if (resp.data.attributes.exists > 0) {
          // tslint:disable-next-line: max-line-length
          message = message + '\n' + resp.data.attributes.exists.toString() + this.translatedResources.productnotimported;
        }
        this.notifier.notify('success', message);
       });
      this.applyFilters();
    });
  }

  selectItem(id) {
    this.selectedItem = (this.selectedItem === id) ? 0 : this.selectedItem = id;
  }

  nuovoProduttore() {
    const dialogRef = this.dialog.open(NuovoProduttoreComponent, {
      height: 'auto',
      width: '500px',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data.id) {
        this.notifier.notify('success', this.translatedResources.successinserted);
        this.router.navigateByUrl(`/admin/produttori/scheda-produttore/${result.data.id}`);
      }
    });
  }

}





