import { JsonapiService } from './../../services/jsonapi.service';
import { DownloadService } from '../../services/Download.service';
import { MessageService } from './../../services/MessageService.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  public loading = true;
  constructor(
    private messageservice: MessageService,
    private downloadservice: DownloadService,
    private jsonapiservice: JsonapiService
  ) { }
  ngOnInit() {

    this.messageservice.filter({ event: 'disable_menu' });
    this.downloadservice.getValue('download').then((value) => {
      const lang = (value.lang) ? value.lang : '';
      const subtype = (value.subtype) ? value.subtype : '';

      this.downloadservice.downloadFiles(value.products, lang, subtype).subscribe(
        (responseData: any) => {
        const a = document.createElement('a');
        const blob = new Blob([responseData], { type: 'application/octet-stream' });
        a.href = URL.createObjectURL(blob);
        a.download = 'download.zip';
        a.click();
        this.downloadservice.removeValue('download');
        this.loading = false;
      }, (error) => {
         this.jsonapiservice.handlingError(error);
      });
    });
  }
  public close() {
      window.close();
  }
}
