import { ErrorResponce } from './../../../../models/jsonapi.model';
import { Component, OnInit } from '@angular/core';
import { Data, Entity } from 'src/app/models/jsonapi.model';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { MessageService } from 'src/app/services/MessageService.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-scheda-negozio',
  templateUrl: './scheda-negozio.component.html',
  styleUrls: ['./scheda-negozio.component.scss']
})
export class SchedaNegozioComponent implements OnInit {
  public apiresource = 'shops';
  public entity: Data = null;
  private readonly notifier: NotifierService;
  public summary: {
    "errors": 0,
    "documents": 0,
    "orders": 0,
    "customersCount": 0,
    "customers": []
  };
  public applicatorClass = '';
  public FormGroup = new FormGroup({
    rag_soc: new FormControl(),
    comune: new FormControl(),
    pec: new FormControl('', [
      Validators.required,
      Validators.pattern("[^ @]*@[^ @]*")
    ]),
    piva: new FormControl('', [
      Validators.required,
    ]),
    note: new FormControl()
  });
  private loader = false;
  constructor(
    private jsonapiservice: JsonapiService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private notifierService: NotifierService,
    private router: Router
  ) {
    this.notifier = notifierService;

  }

  ngOnInit() {
    const entityId = this.route.snapshot.paramMap.get('id');
    this.getEntity(entityId);
    this.messageService.filter({ event: 'selected_route', route: '/admin/negozi' });
    this.messageService.filter({ event: 'enable_menu' });
  }

  public getSummary(entityId) {
    this.jsonapiservice.getEntity('summary', entityId).subscribe((summaryEntity: any) => {
          this.summary = summaryEntity.attributes;
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    }
  );
  }

  public getEntity(entityId) {
    this.getSummary(entityId);
    this.jsonapiservice.getEntity(this.apiresource, entityId).subscribe((entity: Entity) => {
      this.entity = entity.data;
      this.FormGroup.controls.rag_soc.setValue(this.entity.attributes.rag_soc);
      this.FormGroup.controls.comune.setValue(this.entity.attributes.comune);
      this.FormGroup.controls.pec.setValue(this.entity.attributes.pec);
      this.FormGroup.controls.piva.setValue(this.entity.attributes.piva);
      this.FormGroup.controls.note.setValue(this.entity.attributes.note);
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    });
  }

  public save() {
    const entityId = this.route.snapshot.paramMap.get('id');
    const updateData = {
      data: {
        type: this.apiresource,
        id: entityId,
        attributes: {
          rag_soc: this.FormGroup.value.rag_soc,
          piva: this.FormGroup.value.piva,
          pec: this.FormGroup.value.pec,
          comune: this.FormGroup.value.comune,
          note: this.FormGroup.value.note
        }
      }
    };
    this.jsonapiservice.updateEntity(this.apiresource, entityId, updateData).subscribe((resp) => {
      this.notifier.notify('success', 'Scheda negozio aggiornata correttamente');
      setTimeout(() => {
        this.router.navigateByUrl('/admin/negozi');
      }, 1000) ;
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    });
  }

  applicators() {
    this.router.navigate(['/admin/applicatori'], { queryParams:
      {
        shop: this.route.snapshot.paramMap.get('id')
      }
    });
  }




}
