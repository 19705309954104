import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateDiff' })
export class DateDiff implements PipeTransform {
    transform(value: string, date: string): any {
      const d1 = new Date(value);
      const d2 = new Date(date);
      const difference_In_Time = d2.getTime() - d1.getTime();
      const difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
      const difference_In_Month= ( difference_In_Time / (1000 * 3600 * 24) ) / 30;

      if (difference_In_Days < 1 ) {
        return 'oggi';
      }
      if (difference_In_Days === 1 ) {
        return 'ieri';
      }

      if (difference_In_Days > 30) {
        return Math.ceil(difference_In_Month) + ' mesi';
      }
      return Math.ceil(difference_In_Days) + ' giorni';
    }

}
