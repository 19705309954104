import { Component, OnInit } from '@angular/core';
import { JsonapiService, Filter } from 'src/app/services/jsonapi.service';
import { Data, Entity, Collection, ErrorResponce } from 'src/app/models/jsonapi.model';
import { AuthService } from 'src/app/services/auth.service';
import { Credentials, IUser, User } from 'src/app/models/user.model';
import { LanguageService } from 'src/app/services/language.service';
import { appconfig as confen } from 'src/app/config/order.config.en';
import { appconfig as confit } from 'src/app/config/order.config.it';

@Component({
  selector: 'app-licenza',
  templateUrl: './licenza.component.html',
  styleUrls: ['./licenza.component.scss']
})
export class LicenzaComponent implements OnInit {
  public subscriptions: Array<Data>;
  public subscriptionDetail = '0';
  mailto = '';

  constructor(
    private auth: AuthService,
    private jsonapiservice: JsonapiService,
    private languageService: LanguageService
  ) { }

  ngOnInit() {
    const user = new User(this.auth.getLoggedUser());
    if (user.isApplicator()) {
      this.getApplicatorSubscriptions(user.data.attributes.ref_id);
      const account_mail = user.data.attributes.email;
      if ('en' === this.languageService.getLang()) {
        const mail_renew = confen.mails.renew;
        this.mailto = "mailto:" + mail_renew.to;
        this.mailto += "?subject=" + mail_renew.subject;
        this.mailto += "&body=" + mail_renew.body_intro + account_mail + mail_renew.body_greetings;
      } else {
        const mail_renew = confit.mails.renew;
        this.mailto = "mailto:" + mail_renew.to;
        this.mailto += "?subject=" + mail_renew.subject;
        this.mailto += "&body=" + mail_renew.body_intro + account_mail + mail_renew.body_greetings;
      }
    }
  }

  /**
   * ritorna le sottoscrizioni dell'applicatore
   */
  public getApplicatorSubscriptions(entityId) {
    const filters: Array<Filter> = [{name: 'applicator_id', value: entityId}];
    this.jsonapiservice.getCollection('subscriptions', filters)
        .subscribe((data: Collection) => {
          this.subscriptions = data.data;
          if (this.subscriptions) {
            this.subscriptionDetail = this.subscriptions[0].id.toString();
          }
        }, (error: ErrorResponce) => {
          this.jsonapiservice.handlingError(error);
        });
  }

  public showDetail(subscriptionId) {
    if (subscriptionId === this.subscriptionDetail) {
      this.subscriptionDetail = '0';
    } else {
      this.subscriptionDetail = subscriptionId;
    }
  }

}
