import { ErrorResponce } from './../../../models/jsonapi.model';
import { Component, OnInit } from '@angular/core';
import { Paginator } from 'src/app/models/paginator.model';
import { JsonapiService, Filter } from 'src/app/services/jsonapi.service';
import { MessageService } from 'src/app/services/MessageService.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionPaginated } from 'src/app/models/jsonapi.model';

@Component({
  selector: 'app-importazione-sds',
  templateUrl: './importazione-sds.component.html',
  styleUrls: ['./importazione-sds.component.scss']
})

export class ImportazioneSdsComponent implements OnInit {
  public sdsimports = [];
  public paginator: Paginator;
  public orderDirection = '-';
  public orderField = 'created-at';
  public orderIcon = '';
  public recordPerPage = 10;
  public search = '';
  public loading = true;
  constructor(
    private jsonapiservice: JsonapiService,
    private messageservice: MessageService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.messageservice.filter({ event: 'enable_menu' });
    this.messageservice.filter({ event: 'selected_route', route: '/admin/importazioni-schede-sds' });
    this.getCollection();
  }

  applyFilters() {
    const filters: Array<Filter> = [];

    if (this.search !== '') {
      filters.push({ name: 'search', value: this.search });
    }
    this.getCollection(this.paginator['current-page'], this.paginator['per-page'], filters);
  }

/**
 * return the sds imports
 * @param page
 * @param size
 * @param filters
 */
  getCollection(page = 1, size = 10, filters?: Array<Filter>) {
      this.loading = true;
      const orderby = this.orderDirection + this.orderField;
      this.jsonapiservice.getCollectionPaginated('sds-import', page, size, filters, orderby)
        .subscribe((data: CollectionPaginated) => {
          this.paginator  = data.meta.page;
          this.sdsimports = data.data;
          console.log( data.data);
          this.loading    = false;
        }, (error: ErrorResponce) => { this.jsonapiservice.handlingError(error); this.loading = false; });
  }

  /**
   *orderBy
   */
  orderBy(orderField) {
    if (this.orderField !== orderField) {
      this.orderField = orderField;
      this.orderDirection = '';
      this.orderIcon = 'fa-arrow-up';
    } else {
      this.orderDirection = (this.orderDirection === '-') ? '' : '-';
      this.orderIcon = (this.orderDirection === '-') ? 'fa-arrow-down' : 'fa-arrow-up';
    }
    this.getCollection(this.paginator['current-page'], this.recordPerPage);
  }


  pageClick($event) {
    this.paginator  = null;
    this.sdsimports = null;
    this.getCollection($event);
  }

}
