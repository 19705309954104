import { JsonapiService, Filter } from 'src/app/services/jsonapi.service';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/services/MessageService.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { User } from 'src/app/models/user.model';
import { NotifierService } from 'angular-notifier';
import { Paginator } from 'src/app/models/paginator.model';
import { TicketsCollectionPaginated } from 'src/app/models/tickets.model';
import { ErrorResponce } from 'src/app/models/jsonapi.model';


@Component({
  selector: 'app-supporto',
  templateUrl: './supporto.component.html',
  styleUrls: ['./supporto.component.scss']
})
export class SupportoComponent implements OnInit {
  public section = '';
  private readonly notifier: NotifierService;
  public nuovaRichiesta = false;
  public search;
  public tipologia = '0';
  public status = 0;
  public statuses  = ['Nuovo', 'In carico', 'Risolto'];
  public priorities = ['Da definire', 'Normale', 'Alta', 'Critica'];
  public priority = 0;
  public priorityClasses =  [`priority-toDefine`, `priority-normal`, `priority-high`, `priority-critical`] ;
 public statusClasses = [`status-new`, `status-handled`, `status-resolved`];
 public paginator: Paginator;
 public orderDirection = '';
 public orderField = '';
 public orderIcon = '';
 public recordPerPage = 10;
 public ticketToLoad = false;
 public tickets = [];
  constructor(
    private auth: AuthService,
    private messageService: MessageService,
    private jsonapiservice: JsonapiService,
    private notifierService: NotifierService,
    ) {
      this.notifier = notifierService;
    }

  ngOnInit() {
    this.messageService.filter({ event: 'selected_route',  route: '/supporto'});
    this.messageService.filter({ event: 'enable_menu'});
    const user = new User(this.auth.getLoggedUser());
    if (user.isShop()) {
      // carica i tickets
      const filters: Array<Filter> = [];
      filters.push({ name: 'shop', value: user.getRefId() });
      this.getTickets(1, 10, filters);
     }
    if (user.isApplicator()) {
      // carica i tickets
      const filters: Array<Filter> = [];
      filters.push({ name: 'applicator', value: user.getRefId() });
      this.getTickets(1, 10, filters);
     }
  }

  setSection(section: string) {
    this.section = section;
  }
  getTickets(page = 1, size = 10, filters?: Array<Filter>) {
    const orderby = this.orderDirection + this.orderField;
    this.ticketToLoad = false;
    this.jsonapiservice.getCollectionPaginated('ticket', page, size, filters, orderby)
      .subscribe((data: TicketsCollectionPaginated) => {
        this.paginator = data.meta.page;
        this.tickets = data.data;
        this.ticketToLoad = true;
      },  (error: ErrorResponce) => this.jsonapiservice.handlingError(error));
  }

  applyFilters(page = 1) {
    const filters: Array<Filter> = [];
    const user = new User(this.auth.getLoggedUser());
    if (user.isShop()) {
      // carica i tickets
      filters.push({ name: 'shop', value: user.getRefId() });
     }
    if (user.isApplicator()) {
      // carica i tickets
      filters.push({ name: 'applicator', value: user.getRefId() });
     }
    if (this.search && this.search !== '') {
      filters.push({ name: 'search', value: this.search });
    }
    if (this.tipologia !== '0' ) {
      filters.push({ name: 'tipologia', value: this.tipologia });
    }
    if (this.priority !== 0) {
      filters.push({ name: 'priority', value: this.priority });
    }
    if (this.status !== 0) {
      filters.push({ name: 'status', value: this.status });
    }
    console.log('filter', filters);
    this.getTickets(
      page,
      this.recordPerPage, filters);
  }

  orderBy(orderField) {
    const filters: Array<Filter> = [];
    const user = new User(this.auth.getLoggedUser());
    if (user.isShop()) {
      // carica i tickets
      filters.push({ name: 'shop', value: user.getRefId() });
     }
    if (user.isApplicator()) {
      // carica i tickets
      filters.push({ name: 'applicator', value: user.getRefId() });
     }
    if (this.search && this.search !== '') {
      filters.push({ name: 'search', value: this.search });
    }

    if (this.orderField !== orderField) {
      this.orderField = orderField;
      this.orderDirection = '';
      this.orderIcon = 'fa-arrow-up';
    } else {
      this.orderDirection = (this.orderDirection === '-') ? '' : '-';
      this.orderIcon = (this.orderDirection === '-') ? 'fa-arrow-down' : 'fa-arrow-up';
    }
    this.getTickets(this.paginator['current-page'], this.paginator['per-page'], filters);
  }
  pageClick($event) {
    this.paginator = null;
    this.tickets = null;
    this.applyFilters($event);
  }

  newTicket() {
    this.nuovaRichiesta = true;
  }

  hasSended($event) {
    const user = new User(this.auth.getLoggedUser());
    const filters: Array<Filter> = [];
    this.nuovaRichiesta = !$event;
    filters.push({ name: user.isShop() ? 'shop' : 'applicator', value: user.getRefId() });
    this.getTickets(1, 10, filters);
  }

}

