
import { environment } from '../../environments/environment';
import { Injectable, Injector } from '@angular/core';
import 'rxjs-compat';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from './auth.service';
import { LocalStorageService } from 'ngx-store';

@Injectable({
  providedIn: 'root'
}
)
export class DownloadService {
  API_BASE: string = environment[environment.env].base_url + '/' + environment[environment.env].version;
  storagePrefix: string = 'DownloadService_';
  constructor(
    private localStorage: LocalStorageService,
    private authservice: AuthService,
    private injector: Injector
  ) { }

  async setValue(variable: string, value: any) {
    this.localStorage.set(this.storagePrefix + variable, value);
  }

  async getValue(variable) {
    return this.localStorage.get(this.storagePrefix +  variable);
  }

  async removeValue(variable) {
    return this.localStorage.remove(this.storagePrefix + variable);
  }

  public downloadFiles(products: Array<number>, lang?: string, type?: string) {
    const language = (lang) ? lang : '';
    const subtype  = (type) ? type : '';
    const options = this.buildRequestOptions({ responseType: 'Blob'});
    const uri = `${this.API_BASE}/products/files-download`;
    const postData = {
      "data":
      {
        "type": "products",
        "attributes":
        {
          "lang": language,
          "products": products,
          "subtype" : subtype
        }
      }
    };
    return this.injector.get(HttpClient).post(uri, postData, options);
  }

  protected buildRequestOptions(customOptions: any = {}): object {
    const requestOptions: object = Object.assign(customOptions, {
      headers: this.authservice.getJsonApiHeaders()
    });
    return requestOptions;
  }
}
