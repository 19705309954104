import { ErrorResponce } from './../../../models/jsonapi.model';
import { TicketsCollectionPaginated } from './../../../models/tickets.model';
import { JsonapiService, Filter } from './../../../services/jsonapi.service';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/services/MessageService.service';
import { Paginator } from 'src/app/models/paginator.model';
import { Collection } from 'src/app/models/jsonapi.model';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {
  public search;
  public tipologia = '0';
  public status = 0;
  public statuses;
  public priorities;
  public priority = 0;
  public priorityClasses =  [`priority-toDefine`, `priority-normal`, `priority-high`, `priority-critical`] ;
 public statusClasses = [`status-new`, `status-handled`, `status-resolved`];
 public paginator: Paginator;
 public orderDirection = '';
 public orderField = '';
 public orderIcon = '';
 public recordPerPage = 10;
 public ticketToLoad = false;
 public tickets = [];
  constructor(
    private messageService: MessageService,
    private jsonapiservice: JsonapiService
  ) { }

  ngOnInit() {
    this.messageService.filter({ event: 'selected_route', route: '/admin/tickets' });
    this.messageService.filter({ event: 'enable_menu' });
    this.jsonapiservice.translate('i18n.admin.tickets.statuses').subscribe((res: any) => {
      this.statuses = res;
    });
    this.jsonapiservice.translate('i18n.admin.tickets.priorities').subscribe((res: any) => {
      this.priorities = res;
    });
    this.getTickets();

  }

  getTickets(page = 1, size = 10, filters?: Array<Filter>) {
    const orderby = this.orderDirection + this.orderField;
    this.ticketToLoad = false;
    this.jsonapiservice.getCollectionPaginated('ticket', page, size, filters, orderby)
      .subscribe((data: TicketsCollectionPaginated) => {
        this.paginator = data.meta.page;
        this.tickets = data.data;
        this.ticketToLoad = true;
      }, (error: ErrorResponce) => this.jsonapiservice.handlingError(error));
  }

  applyFilters(page = 1) {
    const filters: Array<Filter> = [];

    if (this.search && this.search !== '') {
      filters.push({ name: 'search', value: this.search });
    }
    if (this.tipologia !== '0' ) {
      filters.push({ name: 'tipologia', value: this.tipologia });
    }
    if (this.priority !== 0) {
      filters.push({ name: 'priority', value: this.priority });
    }
    if (this.status !== 0) {
      filters.push({ name: 'status', value: this.status });
    }
    this.getTickets(
      page,
      this.recordPerPage, filters);
  }

  orderBy(orderField) {
    if (this.orderField !== orderField) {
      this.orderField = orderField;
      this.orderDirection = '';
      this.orderIcon = 'fa-arrow-up';
    } else {
      this.orderDirection = (this.orderDirection === '-') ? '' : '-';
      this.orderIcon = (this.orderDirection === '-') ? 'fa-arrow-down' : 'fa-arrow-up';
    }
    const filters: Array<Filter> = [];
    filters.push({ name: 'todo', value: 1 });

  }
  pageClick($event) {
    this.paginator = null;
    this.tickets = null;
    this.applyFilters($event);
  }
}
