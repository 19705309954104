import { ErrorResponce } from './../../../../models/jsonapi.model';
import { ProductEntity, Doc } from './../../../../models/product.model';
import { Component, OnInit } from '@angular/core';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { MessageService } from 'src/app/services/MessageService.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Entity, Data, Collection } from 'src/app/models/jsonapi.model';
import { NotifierService } from 'angular-notifier';
import { LocalJsonService } from 'src/app/services/local.json.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Product } from 'src/app/models/product.model';
import * as moment from 'moment';
import { throwMatDialogContentAlreadyAttachedError, MatDialog } from '@angular/material';
import { ConfirmDeleteComponent } from 'src/app/components/confirm-delete/confirm-delete.component';


import { DownloadService } from 'src/app/services/Download.service';
@Component({
  selector: 'app-scheda-prodotto',
  templateUrl: './scheda-prodotto.component.html',
  styleUrls: ['./scheda-prodotto.component.scss']
})
export class SchedaProdottoComponent implements OnInit {
  public entity: ProductEntity = null;
  public msds = {};
  public tds = {};
  public apiresource = 'products';
  public activeTab = 'dati';
  private readonly notifier: NotifierService;
  public manufacturers = [];
  public tosave = false;
  public loading = false;
  public translatedResources;
  public FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    sku: new FormControl('', Validators.required),
    manufacturer_id: new FormControl('', Validators.required)
  });
  constructor(
    private jsonapiservice: JsonapiService,
    private messageservice: MessageService,
    private route: ActivatedRoute,
    private localjsonservice: LocalJsonService,
    private notifierService: NotifierService,
    private downloadservice: DownloadService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.jsonapiservice.translate('i18n.admin.products').subscribe((res: any) => {
      this.translatedResources = res;
      const entityId = this.route.snapshot.paramMap.get('id');
      this.getEntity(entityId);
      this.getManufacturers();
    });

    this.messageservice.filter({ event: 'enable_menu' });
    this.messageservice.filter({ event: 'selected_route', route: '/admin/prodotti' });
  }


  public getEntity(entityId) {
    this.jsonapiservice.getEntity(this.apiresource, entityId).subscribe((entity: any) => {
      this.entity = entity.data;
      this.FormGroup.controls.name.setValue(this.entity.attributes.name);
      this.FormGroup.controls.sku.setValue(this.entity.attributes.sku);
      this.FormGroup.controls.manufacturer_id.setValue(this.entity.attributes.manufacturer_id);

      if (this.entity.meta.docs.length) {
        this.entity.meta.docs.forEach((documento) => {
          if (documento.subtype === 'msds') {
            this.msds[documento.lang_code.toString()] = { name: documento.title };
          } else {
            this.tds[documento.lang_code] = { name: documento.title };
          }
        });
      }
      /* if (ev.target.id === 'msds') {
        this.msds[this.activeTab] = { name : ev.target.files[0].name, content : encoded};
      } else {
        this.tds[this.activeTab] = { name : ev.target.files[0].name, content : encoded};
      } */
    });
  }
  public salva() {
    const entityId = this.route.snapshot.paramMap.get('id');
    const data = {
      data: {
        id: entityId.toString(),
        type: this.apiresource,
        attributes: {
          name: this.FormGroup.value.name,
          product_updated_at: moment().format('YYYY-MM-DD'),
          sku: this.FormGroup.value.sku,
          manufacturer_id: this.FormGroup.value.manufacturer_id
        }
      }
    };

    this.jsonapiservice.updateEntity(this.apiresource, entityId, data).subscribe((r: any) => {
      this.notifier.notify('info', this.translatedResources.successsaved);
      this.caricaDocumenti();
      this.tosave = false;
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    });
  }

  private getManufacturers() {
    this.loading = true;
    this.manufacturers = [];
    this.manufacturers = [{ id: 0, name: 'Tutti' }];
    this.jsonapiservice.getCollection('manufacturers', null, 'rag_soc').subscribe((data: Collection) => {
      data.data.forEach((item) => {
        this.loading = false;
        this.manufacturers.push({ id: item.id, name: item.attributes.rag_soc });
      });
    }, (error: ErrorResponce) => {this.jsonapiservice.handlingError(error);
    });
  }

  public caricaDocumenti() {
    const entityId = this.route.snapshot.paramMap.get('id');
    const doctypes = ['msds', 'tds'];
    const langs = [{language_code: 'en', language_name : 'English'},
                   {language_code: 'it', language_name : 'Italian'}
                  ];
    let loader = 0;
    doctypes.forEach((doc) => {
      langs.forEach((lang) => {
      if (this[doc][lang.language_code] && this[doc][lang.language_code].content) {
        loader++;
       }
      });
    });


    doctypes.forEach((doc, index) => {
      langs.forEach((lang) => {
        if (this[doc][lang.language_code] && this[doc][lang.language_code].content) {
          const postData = {
            data: {
              attributes: {
                title: this[doc][lang.language_code].name,
                file: this[doc][lang.language_code].content,
                filename: this[doc][lang.language_code].name,
                type: 'product_document',
                subtype: doc,
                entity_type: 'product',
                entity_id: entityId,
                additional_data: JSON.stringify(lang),
                lang: lang.language_code
              }
            },
            type: 'products'
          };

          this.jsonapiservice.postEntityCustomPath( postData, 'documents/create')
          .subscribe((data) => {
            console.log(data);
            loader--;
            if (loader === 0) {
              this.getEntity(entityId);
              this.router.navigateByUrl('/admin/prodotti');
            }
          });
        }
      });
    });
  }

  public loadFile(ev, index = 0) {
    this.tosave = true;
    const reader = new FileReader();
    reader.readAsDataURL(ev.target.files[0]);
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
      if ((encoded.length % 4) > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      if (ev.target.id === 'schedasicurezza') {
        this.msds[this.activeTab] = { name: ev.target.files[0].name, content: encoded };
      } else {
        this.tds[this.activeTab] = { name: ev.target.files[0].name, content: encoded };
      }
    }
  }


  public eliminaProdotto() {
    const productId = this.route.snapshot.paramMap.get('id');
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        title: this.translatedResources.confirmdeleteproduct,
        message: this.translatedResources.confirmdeleteproductmessage
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.jsonapiservice.deleteEntity('products', productId).subscribe((r: any) => {
          this.notifier.notify('info',  this.translatedResources.productdeleted);
          setTimeout(() => {
            this.router.navigateByUrl('/admin/prodotti');
          }, 2000);
        }, (error: ErrorResponce) => { this.jsonapiservice.handlingError(error); });
      }
    });


  }


  public changeTab(tab: string) {
    this.activeTab = tab;
  }

public download(languageCode, filetype) {
  const entityId = this.route.snapshot.paramMap.get('id');
  this.downloadservice.setValue('download', { products: [entityId], lang: languageCode, subtype: filetype });
  const newWindow = window.open('download');
}

}
