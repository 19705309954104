import { CollectionPaginated, ErrorResponce } from './../../../models/jsonapi.model';
import { MessageService } from './../../../services/MessageService.service';
import { JsonapiService, Filter } from './../../../services/jsonapi.service';
import { Paginator } from './../../../models/paginator.model';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { Collection, Entity } from 'src/app/models/jsonapi.model';
import { ProductsSearchDialogComponent } from 'src/app/components/products-search-dialog/products-search-dialog.component';
import { NotifierService } from 'angular-notifier';

interface ImportedResponce {
  data: Data;
}

interface Data {
  type: string;
  attributes: Attributes;
}

interface Attributes {
  imported: number;
  exists: number;
}
@Component({
  selector: 'app-negozi',
  templateUrl: './negozi.component.html',
  styleUrls: ['./negozi.component.scss']
})
export class NegoziComponent implements OnInit {
  public paginator: Paginator;
  public collection: Array<any>;
  public orderDirection = '';
  public orderField = 'rag_soc';
  public orderIcon = '';
  public recordPerPage = 10;
  public filterManufacturer = 0;
  public search = '';
  public loading = false;
  private readonly notifier: NotifierService;

  constructor(
    private jsonapiservice: JsonapiService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private notifierService: NotifierService
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.getCollection();
    this.messageService.filter({ event: 'selected_route', route: '/admin/negozi' });
    this.messageService.filter({ event: 'enable_menu' });
  }

  getCollection(page = 1, size = 10, filters?: Array<Filter>) {
    this.loading = true;
    const orderby = this.orderDirection + this.orderField;
    this.collection = [];
    this.jsonapiservice.getCollectionPaginated('shops', page, size, filters, orderby)
      .subscribe((data: CollectionPaginated) => {
        this.loading = false;
        this.paginator = data.meta.page;
        this.collection = data.data;
      }, (error: ErrorResponce) => {
        this.jsonapiservice.handlingError(error);
      });
  }

  applyFilters() {
    const filters: Array<Filter> = [];
    if (this.search !== '') {
      filters.push({ name: 'search', value: this.search });
    }
    this.getCollection(
      1,
      this.paginator['per-page'], filters);
  }

  orderBy(orderField) {
    if (this.orderField !== orderField) {
      this.orderField = orderField;
      this.orderDirection = '';
      this.orderIcon = 'fa-arrow-up';
    } else {
      this.orderDirection = (this.orderDirection === '-') ? '' : '-';
      this.orderIcon = (this.orderDirection === '-') ? 'fa-arrow-down' : 'fa-arrow-up';
    }
    this.getCollection(this.paginator['current-page'], this.recordPerPage);
  }


  pageClick($event) {
    this.paginator = null;
    this.collection = null;
    this.getCollection($event);
  }

  changeStyle($event) {
    const row = document.getElementById(`row-${$event.target.id}`);

    if (row) {
      if ($event.type === 'mouseover' && row.getAttribute('class') === 'moreinfo') {
        row.setAttribute('class', 'moreinfo active');
      }
      if ($event.type === 'click' ) {
        row.setAttribute('class', 'moreinfo show');
      }

      if ($event.type === 'mouseout' &&  row.getAttribute('class') === 'moreinfo active' ) {
        row.setAttribute('class', 'moreinfo');
      }
    }
  }

  openDialog() {}


}





