import * as moment from 'moment';

export interface IUser {
  data: JsonApiData;
}

export interface JsonApiData {
  id: number;
  type: string;
  attributes: Attributes;
}


export interface Attributes {
  id: number;
  role_id: number;
  name: string;
  surname: string;
  email: string;
  avatar: string;
  email_verified_at?: any;
  valid_until: string;
  lang: string;
  disable_login: number;
  ref_table: string;
  ref_id: number;
  settings: any[];
  created_at: string;
  updated_at: string;
  is_admin: boolean;
  can_login: boolean;
  roles: Role[];
  permissions: any[];
}


export interface Role {
  id: number;
  name: string;
  guard_name: string;
  created_at: string;
  updated_at: string;
  pivot: Pivot;
}

export interface Pivot {
  model_id: number;
  role_id: number;
  model_type: string;
}



export class User implements IUser {
  public data: JsonApiData;
  constructor(userObj: IUser) {
    Object.assign(this, userObj);
  }

  public getId() {
    if (this.data && this.data.id) {
      return this.data.id;
    }
  }

  public getEmail() {
    return this.data.attributes.email;
  }

  public getRefId() {
    // se e' shop da' l'id dello shop
    // se e' applicator da' l'id dell'applicator
    if (this.data && this.data.attributes && this.data.attributes.ref_id) {
         return this.data.attributes.ref_id;
    }
    return 0;
  }

  public getLang() {
    if (this.data && this.data.attributes && this.data.attributes.lang) {
      return this.data.attributes.lang;
    } else {
      return 'it';
    }

  }

  public isNotificable(id, table) {
    if (this.data && this.data.attributes.ref_id === id &&
      this.data.attributes.ref_table === table
    ) {
      return true;
    }
  }

  public hasValidSubscribe() {
    //if (this.data.attributes.valid_until !== null) {
      const date = new Date(this.data.attributes.valid_until);
      const scadenza = moment().diff(date, 'days'); // se positivo subscribe scaduta
      if (this.isApplicator() && scadenza >= 0) {
        return false;
      }
    //}

    return true;
  }

  public isAdmin() {
    let isAdmin = false;

    if (this.data && this.data.attributes.ref_table === '') {
      isAdmin = true;
    }
    return isAdmin;
  }

  public isShop() {
    let isShop = false;
    if (this.data && this.data.attributes.ref_table === 'shop') {
      isShop = true;
    }
    return isShop;
  }

  public isApplicator() {
    let isApplicator = false;
    if (this.data && this.data.attributes.ref_table === 'applicator') {
      isApplicator = true;
    }
    return isApplicator;
  }


}

export interface Credentials {
  username: string;
  password?: string;
}
