import { User } from './models/user.model';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService,
              private myRoute: Router) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.auth.getToken()) {
      const user = new User(this.auth.getLoggedUser());
      const commonRoutes = ['dashboard', 'logout', 'supporto', 'download', '/'];
      const requestPath  = next.url;

      if (requestPath.length === 0) {
        return true;
      }


      if (commonRoutes.indexOf(requestPath[0].path) > -1) {
        return true;
      }

      if (user.isAdmin() && requestPath[0] && requestPath[0].path === 'admin') {
        return true;
      }

      if (user.isShop() && requestPath[0] && requestPath[0].path === 'shops') {
       return true;
      }

      if (user.isApplicator() && requestPath[0] && requestPath[0].path === 'applicator') {
        return true;
       }
      return false;


     // to do
     // if (next.routeConfig.path.search('applicator') || next.routeConfig.path.search('dashboard'))
     // return true;
    } else {
      this.myRoute.navigate(['login']);
      return false;
    }
  }

}
