import { JsonapiService } from 'src/app/services/jsonapi.service';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/services/MessageService.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorResponce } from 'src/app/models/jsonapi.model';

@Component({
  selector: 'app-scheda-cliente',
  templateUrl: './scheda-cliente.component.html',
  styleUrls: ['./scheda-cliente.component.scss']
})


export class SchedaClienteComponent implements OnInit {
  public summary = {errors : 0, documents : 0, orders: 0};
  public loader = false;
  public customer = null;
  constructor(
    private jsonapiservice: JsonapiService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    const customerId = this.route.snapshot.paramMap.get('id');
    this.messageService.filter({ event: 'selected_route', route: '/shops/clienti' });
    this.messageService.filter({ event: 'enable_menu' });
    this.getEntity(customerId);
    this.getTotals();
  }
  getTotals() {
    const customerId = this.route.snapshot.paramMap.get('id');
    this.jsonapiservice.customGetRequest(`orders/summary/${customerId}`).subscribe((data: any) => {
     this.summary.errors = data.attributes.errors;
     this.summary.documents = data.attributes.documents;
     this.summary.orders = data.attributes.orders;
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    });
  }

  public getEntity(id) {
    this.loader = true;
    this.jsonapiservice.getEntity('applicators', id).subscribe((applicatore: any) => {
    this.loader = false;
    this.customer = applicatore;
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    });
  }

  public showErrors() {
    setTimeout(() => {
      this.messageService.filter(
        { event: 'search_orders',
          applicator_id: this.route.snapshot.paramMap.get('id'),
          error: 1
        });
    }, 500);
    this.router.navigate(['/']);
  }

  public showOrders() {
      this.router.navigate(['/'], { queryParams:
        {
          applicator: this.route.snapshot.paramMap.get('id')
        }
      });

  }

}
