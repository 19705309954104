
import { environment } from './../environments/environment';
import { Component, OnInit, ViewChild, OnChanges } from '@angular/core';
import { SocketService } from './services/socket.service';
import { MessageService } from './services/MessageService.service';
import { NotifierService } from 'angular-notifier';
import { AuthService } from './services/auth.service';
import { User } from './models/user.model';
import { LanguageService } from './services/language.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  //styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnChanges {


  title = 'safeup';
  private readonly notifier: NotifierService;
  constructor(
    private socketservice: SocketService,
    private messageService: MessageService,
    private notifierService: NotifierService,
    private auth: AuthService,
    private languageService: LanguageService,
    private titleservice: Title
  ) {
    this.notifier = notifierService;
  }
  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
  }
  ngOnInit() {
   // this.languageService.setInitialAppLanguage();
    if (this.auth.getToken()) {

      console.log('AUTHORIZED');
      const user = new User(this.auth.getLoggedUser());
      this.languageService.setLanguage(user.getLang());
      this.setTitle(user);
      if (environment.socket) {
        this.socketservice.initSocket();
        this.socketservice.onMessage().subscribe((data) => {
          /* console.log(data);
          if (data.payload.suggest) {
            if (user.isAdmin()) {
              // viene avvertito della notifica
              this.messageService.filter({ event: 'socket_message', payload: data });
              this.notifierService.notify('info', data.payload.title);
            } else {
              const message = 'Unnotificable user';
            }
          } */
          if (data.payload.order) {
            if (user.isNotificable(data.payload.order.shop_id, 'shop')) {
              // @todo aggiornare qui il numero delle notifiche nella header
              this.messageService.filter({ event: 'socket_message', payload: data });
              this.notifierService.notify('info', data.payload.title);
            } else {
              const message = 'Unnotificable user';
            }
          }

          if (data.payload.contract) {
            if (user.isNotificable(data.payload.contract.applicator_id, 'applicator')) {
              // @todo aggiornare qui il numero delle notifiche nella header
              // aggiornare la pagina della scheda del contratto
              this.messageService.filter({ event: 'socket_message', payload: data });
              this.notifierService.notify('info', data.payload.title);
            } else {
              const message = 'Unnotificable user';
            }
          }
        });
      }
    } else {
      console.log('NOT AUTHORIZED');
    }
  }

  private setTitle(user: User) {
    const title = user.isAdmin() ? 'Admin' : (user.isShop() ? 'Shop' : 'Applicator');
    this.titleservice.setTitle(this.titleservice.getTitle() + ' - ' + title);
  }


}
