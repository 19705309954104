import { Component, OnInit } from '@angular/core';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { MessageService } from 'src/app/services/MessageService.service';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-nuovo-abbonamento',
  templateUrl: './nuovo-abbonamento.component.html',
  styleUrls: ['./nuovo-abbonamento.component.scss']
})
export class NuovoAbbonamentoComponent implements OnInit {

  constructor(
    private jsonapiservice: JsonapiService,
    private messageService: MessageService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.messageService.filter({ event: 'selected_route', route: '/admin/abbonamenti' });
    this.messageService.filter({ event: 'enable_menu' });
  }

}
