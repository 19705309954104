import { ErrorResponce } from './../../../../models/jsonapi.model';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { MessageService } from 'src/app/services/MessageService.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'app-nuovo-produttore',
  templateUrl: './nuovo-produttore.component.html',
  styleUrls: ['./nuovo-produttore.component.scss']
})
export class NuovoProduttoreComponent implements OnInit {
  public manufacturers = null;
  private type = 'manufacturers';
  private entity = null;
  FormGroup = new FormGroup({
    rag_soc: new FormControl(),
    pec: new FormControl('', [
      Validators.pattern("[^ @]*@[^ @]*")
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern("[^ @]*@[^ @]*")
    ]),
    piva: new FormControl('', [
      Validators.required,
    //  Validators.maxLength(11)
    ])
  });
  constructor(
    private jsonapiservice: JsonapiService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<NuovoProduttoreComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  ngOnInit() {

  }

  public save() {
    const data = {
      data:
      {
        type: this.type,
        attributes: {
          rag_soc: this.FormGroup.value.rag_soc,
          email: this.FormGroup.value.email,
          pec: this.FormGroup.value.pec,
          piva: this.FormGroup.value.piva,
        }
      }
    };
    this.jsonapiservice.postEntity(this.type, data).subscribe((resp) => {
      this.entity = resp;
      this.dialogRef.close(this.entity);
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    });
  }


}
