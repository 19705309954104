
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-store';
const LNG_KEY = 'SELECTED_LANGUAGE';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  selected = '';

  constructor(private translate: TranslateService, private storage: LocalStorageService) { }

  setInitialAppLanguage() {
  //const language = 'en';//this.translate.getBrowserLang();
    const language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);
    this.setLanguage(language);
   // const value =  this.storage.get(LNG_KEY);
    /* if (value) {
      this.setLanguage(value);
    } else { */

    //}
  }

  getLang(): string {
   return this.storage.get(LNG_KEY);
  }

  getLanguages() {
    return [
      { text: 'English', value: 'en', img: 'assets/imgs/en.png' },
      { text: 'Italian', value: 'it', img: 'assets/imgs/it.png' },
    ];
  }

  setLanguage(lng) {
    this.translate.use(lng);
    this.selected = lng;
    this.storage.set(LNG_KEY, lng);
  }
}
