import { Paginator } from './../../models/paginator.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  public paginator: Paginator;
  public pages;
  @Input() set setPaginator(val: Paginator) {
    this.paginator = val;
  }

  @Output() pageClick  = new EventEmitter();

  constructor() {
    this.pages = Array(5).fill(0).map((x, i ) => i);
  }

  public onPageClick(page: number) {
    this.pageClick.emit(page);
  }

  public onNextClick() {
    if (this.paginator['current-page'] < this.paginator.total) {
      this.pageClick.emit(this.paginator['current-page'] + 1);
    }
  }

  public onPrevClick() {
    if (this.paginator['current-page'] > 1) {
      this.pageClick.emit(this.paginator['current-page'] - 1);
    }
  }

  public onFirstClick() {
    this.pageClick.emit(1);
  }

  public onLastClick() {
    this.pageClick.emit(this.paginator['last-page']);
  }

  ngOnInit() {
  }

}
