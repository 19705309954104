import { CollectionPaginated, Entity, ErrorResponce } from './../../../models/jsonapi.model';
import { Collection } from 'src/app/models/jsonapi.model';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { JsonapiService, Filter } from 'src/app/services/jsonapi.service';
import { MessageService } from 'src/app/services/MessageService.service';
import { Paginator } from 'src/app/models/paginator.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-applicatori',
  templateUrl: './applicatori.component.html',
  styleUrls: ['./applicatori.component.scss']
})
export class ApplicatoriComponent implements OnInit {
  public applicators = [];
  public paginator: Paginator;
  public orderDirection = '';
  public orderField = 'rag_soc';
  public orderIcon = '';
  public recordPerPage = 10;
  public abbonamenti = ['Basic', 'Advanced', 'Professional', 'Demo'];
  public search = '';
  public filterAbbonamento = '';
  public asShop = null;
  public shop = null;
  public loading = true;
  constructor(
    private oauth: AuthService,
    private jsonapiservice: JsonapiService,
    private messageservice: MessageService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.messageservice.filter({ event: 'enable_menu' });
    this.messageservice.filter({ event: 'selected_route', route: '/admin/applicatori' });
    this.route.queryParams.subscribe(params => {
      this.asShop = params.shop;
      const filters: Array<Filter> = [];
      // if (this.asShop) {
      //   filters.push({ name: 'shop_id', value: this.asShop });
      if (this.asShop) {
        this.getShop(this.asShop);
      }
      // }
      this.getApplicators(1, 10, filters);
    });
}

  public getShop(shopId) {
    this.shop = null;
    this.jsonapiservice.getEntity('shops', shopId).subscribe((shop: Entity) => {
        this.shop = shop.data.attributes;
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    });
  }

  public getApplicators(page = 1, size = 10, filters?: Array<Filter>) {
      this.loading = true;
      this.applicators = [];

      // TODO: se l'attiviamo, attivarlo anche nella sezione di dettaglio di shop, dove c'e'
      // il numero di applicatori, ma dove non viene tenuto conto della subscription
      // filters.push({name: 'has_subscription', value: true});

      const orderby = this.orderDirection + this.orderField;
      if (this.asShop) {
        filters.push({ name: 'shop_id', value: this.asShop });
      }
      this.jsonapiservice.getCollectionPaginated('applicators-view', page, size, filters, orderby)
        .subscribe((data: CollectionPaginated) => {
          this.paginator = data.meta.page;
          this.applicators = data.data;
          this.loading = false;
        }, (error: ErrorResponce) => {
          this.jsonapiservice.handlingError(error);
        });
  }
  public orderBy(orderField) {
    if (this.orderField !== orderField) {
      this.orderField = orderField;
      this.orderDirection = '';
      this.orderIcon = 'fa-arrow-up';
    } else {
      this.orderDirection = (this.orderDirection === '-') ? '' : '-';
      this.orderIcon = (this.orderDirection === '-') ? 'fa-arrow-down' : 'fa-arrow-up';
    }
    this.getApplicators(this.paginator['current-page'], this.recordPerPage);
  }
  applyFilters() {
    const filters: Array<Filter> = [];
    if (this.filterAbbonamento !== '') {
      console.log(this.filterAbbonamento);
      filters.push({ name: 'subscription_tipologia', value: this.filterAbbonamento });
    }
    if (this.search !== '') {
      filters.push({ name: 'search', value: this.search });
    }
    // if (this.asShop) {
    //   filters.push({ name: 'shop_id', value: this.asShop });
    // }

    this.getApplicators(
      1,
      this.recordPerPage, filters);
  }

  pageClick($event) {
    this.paginator = null;
    this.applicators = null;
    this.getApplicators($event);
  }

  resetFilters() {
    this.asShop = null;
    this.shop = null;
    this.router.navigate(['/admin/applicatori']);
  }
}
