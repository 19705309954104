import { ErrorResponce } from './../../../../models/jsonapi.model';
import { TranslateService } from '@ngx-translate/core';
import { Filter } from './../../../../services/jsonapi.service';
import { Data, Entity, Collection } from 'src/app/models/jsonapi.model';
import { Component, OnInit } from '@angular/core';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { MessageService } from 'src/app/services/MessageService.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LocalJsonService } from 'src/app/services/local.json.service';
import { NotifierService } from 'angular-notifier';
import { AuthService } from 'src/app/services/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-scheda-applicatore',
  templateUrl: './scheda-applicatore.component.html',
  styleUrls: ['./scheda-applicatore.component.scss']
})
export class SchedaApplicatoreComponent implements OnInit {
  public entity: Data = null;
  private apiresource = 'applicators';
  public activeTab = 'dati';
  public province: Array<any>;
  public subscriptions: Array<Data>;
  public orders: Array<Data>;
  public subscriptionDetail = '0';
  public FormGroup = new FormGroup({
    rag_soc: new FormControl(),
    comune: new FormControl(),
    pec: new FormControl('', [
      Validators.required,
      Validators.pattern("[^ @]*@[^ @]*")
    ]),
    email: new FormControl('', [
      Validators.pattern("[^ @]*@[^ @]*")
    ]),
    indirizzo: new FormControl(),
    provincia: new FormControl(),
    cap: new FormControl(),
    ncivico: new FormControl(),
    piva: new FormControl('', [
      Validators.required,
    ]),
    note: new FormControl()
  });
  private readonly notifier: NotifierService;
  constructor(
    private jsonapiservice: JsonapiService,
    private auth: AuthService,
    private messageservice: MessageService,
    private route: ActivatedRoute,
    private localjsonservice: LocalJsonService,
    private notifierService: NotifierService,
    private router: Router,
    private translateService: TranslateService,
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    const entityId = this.route.snapshot.paramMap.get('id');
    this.getEntity(entityId);
    this.getApplicatorSubscriptions(entityId);
    this.getApplicatorOrders(entityId);
    this.messageservice.filter({ event: 'enable_menu' });
    this.messageservice.filter({ event: 'selected_route', route: '/admin/applicatori' });
    this.localjsonservice.getLocalJson('province.json').subscribe((data: Array<any>) => {
      this.province = data;
    });


  }
  /**
   * ritorna le sottoscrizioni dell'applicatore
   */
  public getApplicatorSubscriptions(entityId) {
    const filters: Array<Filter> = [{name: 'applicator_id', value: entityId}];
    this.jsonapiservice.getCollection('subscriptions', filters)
        .subscribe((data: Collection) => {
          this.subscriptions = data.data;
          if (this.subscriptions) {
            const currentSubscriptionIndex = this.getCurrentSubscriptionIndex();
            this.subscriptionDetail = this.subscriptions[currentSubscriptionIndex].id.toString();
          }
        }, (error: ErrorResponce) => {
          this.jsonapiservice.handlingError(error);
        });
  }

  public getApplicatorOrders(entityId) {
    const filters: Array<Filter> = [{name: 'applicator_id', value: entityId}];
    this.jsonapiservice.getCollection('order-view', filters, '-dta_order')
        .subscribe((data: Collection) => {
          this.orders = data.data;
          // console.log(this.orders);
        }, (error: ErrorResponce) => { this.jsonapiservice.handlingError(error)});
  }
  /**
   * Ritorna l'entita
   * @param entityId
   */
  public getEntity(entityId) {
    this.jsonapiservice.getEntity(this.apiresource, entityId).subscribe((entity: Entity) => {
      this.entity = entity.data;
      this.FormGroup.controls.rag_soc.setValue(this.entity.attributes.rag_soc);
      this.FormGroup.controls.comune.setValue(this.entity.attributes.comune);
      this.FormGroup.controls.pec.setValue(this.entity.attributes.pec);
      this.FormGroup.controls.email.setValue(this.entity.attributes.email);
      this.FormGroup.controls.piva.setValue(this.entity.attributes.piva);
      this.FormGroup.controls.indirizzo.setValue(this.entity.attributes.indirizzo);
      this.FormGroup.controls.provincia.setValue(this.entity.attributes.provincia);
      this.FormGroup.controls.cap.setValue(this.entity.attributes.cap);
      this.FormGroup.controls.ncivico.setValue(this.entity.attributes.ncivico);
      this.FormGroup.controls.note.setValue(this.entity.attributes.note);
    }, (error: ErrorResponce) => this.jsonapiservice.handlingError(error));
  }

  public showDetail(subscriptionId) {
    if (subscriptionId === this.subscriptionDetail) {
      this.subscriptionDetail = '0';
    } else {
      this.subscriptionDetail = subscriptionId;
    }
  }

  public changeTab(tab: string) {
    this.activeTab = tab;
  }

  public save() {
    const entityId = this.route.snapshot.paramMap.get('id');
    const updateData = {
      data: {
        type: this.apiresource ,
        id: entityId,
        attributes: {
          rag_soc: this.FormGroup.value.rag_soc,
          piva: this.FormGroup.value.piva,
          pec: this.FormGroup.value.pec,
          email: this.FormGroup.value.email,
          indirizzo: this.FormGroup.value.indirizzo,
          comune: this.FormGroup.value.comune,
          ncivico: this.FormGroup.value.ncivico,
          provincia: this.FormGroup.value.provincia,
          cap: this.FormGroup.value.cap,
          note: this.FormGroup.value.note
        }
      }
    };
    this.jsonapiservice.updateEntity(this.apiresource, entityId, updateData).subscribe((resp) => {
       this.notifier.notify('success', 'Scheda applicatore aggiornata correttamente');
       setTimeout(() => {
        this.router.navigateByUrl('/admin/applicatori');
      }, 1000) ;
      }, (error: ErrorResponce) => {
        this.jsonapiservice.handlingError(error);
    });
  }

  public addSubscription() {
    const piva = this.entity.attributes.piva;
    const lastSubscriptionIndex = this.getlastSubscriptionIndex();
    let start_date = moment(this.subscriptions[lastSubscriptionIndex].attributes.dta_end, 'YYYY-MM-DD').add(1, 'days');
    if (moment().isAfter(start_date)) {
      start_date = moment();
    }
    const end_date = moment(start_date).subtract(1, 'days').add(1, 'years');
    this.auth.renewSubcription(piva, start_date.format('YYYY-MM-DD'), end_date.format('YYYY-MM-DD')).subscribe((subscription: any) => {
      if (subscription && subscription.success) {
        this.notifier.notify('success', 'Licenza applicatore rinnovata correttamente');
        const entityId = this.entity.id;
        setTimeout(() => {
          this.getApplicatorSubscriptions(entityId);
        }, 1000) ;
      }
    }, (err: ErrorResponce) => {
      this.jsonapiservice.handlingError(err);
    });
  }

  public getlastSubscriptionIndex() {
    let subscriptionIndex = -1;
    this.subscriptions.forEach((subscription, index) => {
      if ((subscriptionIndex < 0) || (subscription.attributes.dta_end > this.subscriptions[subscriptionIndex].attributes.dta_end)) {
        subscriptionIndex = index;
      }
    });
    return subscriptionIndex;
  }

  public getCurrentSubscriptionIndex() {
    let subscriptionIndex = -1;
    this.subscriptions.forEach((subscription, index) => {
      if (subscriptionIndex < 0) {
        subscriptionIndex = index;
      }
      else {
        if (
          (moment().isSame(subscription.attributes.dta_start, 'day')) ||
          (moment().isBetween(subscription.attributes.dta_start, subscription.attributes.dta_end, 'day')) ||
          (moment().isSame(subscription.attributes.dta_end, 'day'))
        ) {
          subscriptionIndex = index;
        }
      }
    });
    return subscriptionIndex;
  }
}
