import { LanguageService } from './../../services/language.service';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { ErrorResponce } from './../../models/jsonapi.model';
import { environment } from './../../../environments/environment';
import { Token } from './../../models/token.model';
import { Credentials, IUser, User } from './../../models/user.model';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatRadioChange } from '@angular/material';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  form;
  public error;
  public tokenExpire;
  public showpass = false;
  public version = null;
  constructor(
    private fb: FormBuilder,
    private myRoute: Router,
    private auth: AuthService,
    private jsonapiservice: JsonapiService,
    private languageService: LanguageService
  ) {
    this.form = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }
  ngOnInit() {
    this.version = environment.version;
    this.languageService.setInitialAppLanguage();
  }

  showPass() {
     this.showpass = ! this.showpass;
  }

  login() {
    if (this.form.valid) {
      this.auth.login({ username: this.form.value.email, password: this.form.value.password }).subscribe((data: Token) => {
        if (data.access_token) {
          const token = data.access_token;
          this.auth.sendToken(token);
          this.auth.getUser().subscribe((user: IUser) => {
            const SafeUpUser = new User(user);
            if (SafeUpUser.hasValidSubscribe()) {
              this.auth.setUser(SafeUpUser);
              window.location.href = '/dashboard';
            } else {
              this.auth.logout(token);
              // console.log('SafeUpUser', SafeUpUser.data.attributes.email);
              let param = (SafeUpUser && SafeUpUser.data && SafeUpUser.data.attributes && SafeUpUser.data.attributes.email) ? '/' + SafeUpUser.data.attributes.email : '';
              window.location.href = '/subscribe-expired' + param;
            }
            //
          });
        }
      },
        (error: ErrorResponce) => {
          this.error = error;
       //   this.jsonapiservice.handlingError(error);
        }
      );
      //
    }
  }

  /* setRefreshToken(refresh_token: string, expires_in: number) {
    setTimeout(() => {
      this.auth.refresh(refresh_token).subscribe((token) => {
        this.setRefreshToken(token, expires_in);
      });
    }, expires_in - 31622000 );
  } */

}
