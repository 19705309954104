import { ErrorResponce, Entity } from './../../../../models/jsonapi.model';
import { CustomerEntity } from './../../../../models/customer.model';
import { MessageService } from 'src/app/services/MessageService.service';
import { Customer } from 'src/app/models/customer.model';
import { JsonapiService, Filter } from './../../../../services/jsonapi.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Contract } from 'src/app/models/contract.model';
import { Collection } from 'src/app/models/jsonapi.model';
import { Product } from 'src/app/models/product.model';
import { NotifierService } from 'angular-notifier';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ApplicatorProductCollectionPaginated, ApplicatorProductEntity } from 'src/app/models/applicatorproduct.model';
import { Paginator } from 'src/app/models/paginator.model';
import { filter, take } from 'rxjs/operators';
import { throwMatDialogContentAlreadyAttachedError, MatDialog } from '@angular/material';
import { DownloadService } from 'src/app/services/Download.service';
import { NuovoClienteComponent } from '../../clienti/nuovo-cliente/nuovo-cliente.component';
import { ConfirmDeleteComponent } from 'src/app/components/confirm-delete/confirm-delete.component';


@Component({
  selector: 'app-scheda-appalto',
  templateUrl: './scheda-appalto.component.html',
  styleUrls: ['./scheda-appalto.component.scss']
})
export class SchedaAppaltoComponent implements OnInit {
  public action = '';
  public contract = null;
  public customer = null;
  public products: Array<any>;
  public paginator: Paginator;
  public manufacturers: Array<any>;
  public filterManufacturer = 0;
  public customers: Array<any>;
  public search = '';
  public selectedProducts = [];
  private readonly notifier: NotifierService;
  public phase = null;
  public contractId = null;
  public orderDirection = '';
  public orderField = 'name';
  public orderIcon = '';
  public recordPerPage = 10;
  public reachedTheEnd = false;
  public showSelectedProducts = false;
  public contractModel = {
    name: null,
    dta_end_of_works: '',
    sended: false,
    status: 'bozza',
    customer_id: null,
    products: [],
  };
  public loading = true;
  public pecs = null;
  public translatedResources;
  public oggi = null;

  constructor(
    private jsonapiservice: JsonapiService,
    private messageservice: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private notifierService: NotifierService,
    private downloadservice: DownloadService,
    public dialog: MatDialog
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    const contractId = this.route.snapshot.paramMap.get('id');
    this.messageservice.listen().subscribe((m: any) => {
      if (m.event === 'socket_message') {
        this.getPecsHistory();
      }
    });

    const today = new Date();
    this.oggi = today.toISOString();
    this.jsonapiservice.translate('i18n.applicator.contracts').subscribe((res: any) => {
      this.translatedResources = res;
      this.getContract(contractId);
    });
    // this.messageService.filter({ event: 'socket_message', payload : data });
  }

  public setAction(action: string) {
    this.action = action;
    if (this.action !== 'edit') {
      this.messageservice.filter({ event: 'disable_menu' });
    } else {
      this.messageservice.filter({ event: 'enable_menu' });
      this.messageservice.filter({ event: 'selected_route', route: '/applicator/appalti' });
      this.getPecsHistory();
    }
  }

  private getPecsHistory() {
    const contractId = this.route.snapshot.paramMap.get('id');
    this.jsonapiservice.customGetRequest(`contracts/pecs/${contractId}`).subscribe((data: any) => {
      this.pecs = data.data;
    }, (error: ErrorResponce) => this.jsonapiservice.handlingError(error));
  }

  private getManufacturers() {
    this.manufacturers = [{ id: 0, name: 'Tutti' }];
    this.jsonapiservice.getCollection('manufacturers', null, 'rag_soc').subscribe((data: Collection) => {
      data.data.forEach((item) => {
        this.manufacturers.push({ id: item.id, name: item.attributes.rag_soc });
      });
    }, (error: ErrorResponce) => this.jsonapiservice.handlingError(error));
  }

  private searchInRows(id) {
    let i = -1;
    this.contract.rows.forEach((element, index) => {
      if (element.applicator_products_id === Number(id)) {
        i = index;
      }
    });
    return i;
  }

  public setProduct(product) {
    const c = this.searchInRows(product.id);
    if (c > -1) {
      this.contract.rows.splice(c, 1);
    } else {
      this.contract.rows.push(
        {
          id: null,
          name: product.name,
          sku: product.sku,
          product_type: product.product_type,
          manufacturer: product.manufacturer,
          product_imported_at: product.product_imported_at,
          applicator_products_id: Number(product.id),
          contract_id: this.contract.id,
          created_at: product['created-at'],
          updated_at: product['updated-at'],
        });
    }
    let found = false;
    this.selectedProducts.forEach((o, i) => {
      if (Number(o) === Number(product.id)) {
        found = true;
        this.selectedProducts.splice(i, 1);
      }
    });
    if (!found) {
      this.selectedProducts.push(Number(product.id));
    }
  }

  private getContractEntity(contractId) {
    this.jsonapiservice.getEntity('contracts', contractId)
      .pipe(
        take(1)
      )
      .subscribe((r: Entity) => {
        this.contract = r.data.attributes;
      }, (error: ErrorResponce) => this.jsonapiservice.handlingError(error));
  }

  private getContract(contractId) {
    this.messageservice.filter({ event: 'disable_menu' });
    this.jsonapiservice.getEntity('contracts', contractId)
      .pipe(
        take(1)
      )
      .subscribe((r: Entity) => {
        this.contract = r.data.attributes;

        if ((this.contract.status === 'attivo') || (this.contract.status === 'terminato')) {
          this.setAction('edit');
        }

        if (this.contract.customer_id) {
          this.getCustomer(this.contract.customer_id);
        }

        if (this.contract.rows.length === 0) {
          this.setPhase(1);
        }
        if (this.contract.rows.length && !this.contract.customer_id) {
          this.selectedProducts = [];
          this.contract.rows.forEach((p) => {
            this.selectedProducts.push(p.applicator_products_id);
          });
          this.phase = 2;
          this.orderField = 'rag_soc';
          this.getCustomers();
        }

        if (this.contract.rows.length && this.contract.customer_id) {
          this.selectedProducts = [];
          this.contract.rows.forEach((p) => {
            this.selectedProducts.push(p.applicator_products_id);
          });
          this.reachedTheEnd = true;
          this.phase = 3; // riepilogo e conferma
          // recupero cliente
          this.getCustomer(this.contract.customer_id);
        }
        this.loading = false;
      }, (error: ErrorResponce) => {
        this.loading = false;
        this.jsonapiservice.handlingError(error);
      } );
  }

  public getCustomer(customerId) {
    this.contract.customer_id = customerId;
    this.jsonapiservice.getEntity('customers', customerId)
      .pipe(
        take(1)
      )
      .subscribe((r: Entity) => {
        this.customer = r.data.attributes;
      });
  }

  public exit() {
    this.router.navigate(['applicator/appalti']);
  }

  private getProducts(page = 1, size = 10, filters?: Array<Filter>) {
    const orderby = this.orderDirection + this.orderField;
    this.jsonapiservice.getCollectionPaginated('applicator-products', page, size, filters, orderby)
      .subscribe((data: ApplicatorProductCollectionPaginated) => {
        if (!this.showSelectedProducts) {
          this.paginator = data.meta.page;
          this.products = [];
          data.data.forEach((product) => {
            this.products.push(
              {
                id: product.id,
                name: product.attributes.name,
                sku: product.attributes.sku,
                product_type: product.attributes.product_type,
                manufacturer: product.attributes.manufacturer,
                product_imported_at: product.attributes.product_imported_at,
                applicator_products_id: Number(product.id),
                contract_id: this.contract.id,
                created_at: product.attributes['created-at'],
                updated_at: product.attributes['updated-at'],
              });
          });
        }
      }, (error) => { this.jsonapiservice.handlingError(error); });
  }

  public showSelected($event) {
    // $event.preventDefault();
    this.showSelectedProducts = !this.showSelectedProducts;
    if (this.showSelectedProducts) {
      this.products = [];
      this.contract.rows.forEach(element => {
        this.products.push({
          id: element.applicator_products_id,
          name: element.name,
          sku: element.sku,
          product_type: element.product_type,
          manufacturer: element.manufacturer,
          product_imported_at: element.product_imported_at,
          contract_id: this.contract.id,
          created_at: element['created-at'],
          updated_at: element['updated-at'],
        });
      });
    } else {
      this.applyFilters();
    }
  }

  contractState(data: any) {
    const contractState = data.status;
    const draftState = 'bozza';
    const activeState = 'attivo';
    const unactiveState = 'terminato';

    if (!(data.name) || !(data.name.length)) {
      return draftState;
    }
    if (!(data.dta_end_of_works) || !(data.dta_end_of_works.length)) {
      return draftState;
    }
    if (!(data.customer_id) || !(data.customer_id.length)) {
      return draftState;
    }
    if (!(data.rows) || !(data.rows.length)) {
      return draftState;
    }

    const selectedDate = new Date(data.dta_end_of_works);
    let now = new Date();
    now.setHours(0,0,0,0);
    if (selectedDate < now) {
      return unactiveState;
    }
    return activeState;
  }

  public setPhase(step: number, r = 0) {
    const contractId = this.route.snapshot.paramMap.get('id');
    this.phase = step;
    if (this.action === 'edit' && step !== 3) {
      this.messageservice.filter({ event: 'disable_menu' });
    }
    if (this.action === 'edit' && step === 3) {
      this.messageservice.filter({ event: 'enable_menu' });
    }
    if (this.action === '' && step === 3) {
      this.messageservice.filter({ event: 'disable_menu' });
    }
    // this.setAction('');
    if (this.phase === 1) {
      const statusUpdated = this.contractState(this.contract);
      const appaltoData = {
        data: {
          id: contractId,
          type: 'contracts',
          attributes: {
            name: this.contract.name,
            dta_end_of_works: moment(this.contract.dta_end_of_works).format('YYYY-MM-DD'),
            sended: this.contract.sended,
            status: statusUpdated,
            customer_id: this.contract.customer_id,
            products: this.selectedProducts
          }
        }
      };

      this.jsonapiservice.updateEntity('contracts', contractId, appaltoData)
      .subscribe(( r: any) => {
      }, (error: ErrorResponce) => { this.jsonapiservice.handlingError(error); });
      this.getManufacturers();
      if (this.orderField === 'rag_soc') {
        this.orderField = 'product_imported_at';
        this.orderDirection = '-';
      }
      this.getProducts();
    }
    if (this.phase === 2) {
      const statusUpdated = this.contractState(this.contract);
      // salvo i prodotti selezionati nel databases.
      this.orderField = 'rag_soc';
      this.orderDirection = '';
      this.getCustomers();
      const appaltoData = {
        data: {
          id: contractId,
          type: 'contracts',
          attributes: {
            name: this.contract.name,
            dta_end_of_works: moment(this.contract.dta_end_of_works).format('YYYY-MM-DD'),
            sended: this.contract.sended,
            status: statusUpdated,
            customer_id: this.contract.customer_id,
            products: this.selectedProducts
          }
        }
      };
      console.log('cerco di salvare :', appaltoData);
      this.jsonapiservice.updateEntity('contracts', contractId, appaltoData).subscribe((responce: Entity) => {
        this.contract.rows = responce.data.attributes.rows;
        return;
        if (this.contract.status === 'bozza') {
          //   this.notifier.notify('info', 'Appalto salvato come bozza');
        }
      }, (error: ErrorResponce) => { this.jsonapiservice.handlingError(error); });
    }
    if (this.phase === 3) {
      const statusUpdated = this.contractState(this.contract);
      const appaltoData = {
        data: {
          id: contractId,
          type: 'contracts',
          attributes: {
            name: this.contract.name,
            dta_end_of_works: moment(this.contract.dta_end_of_works).format('YYYY-MM-DD'),
            sended: this.contract.sended,
            status: statusUpdated,
            customer_id: this.contract.customer_id,
            products: this.selectedProducts
          }
        }
      };

      this.jsonapiservice.updateEntity('contracts', contractId, appaltoData).subscribe((r: any) => {
        this.getContractEntity(contractId);
      }, (error: ErrorResponce) => { this.jsonapiservice.handlingError(error); });
      this.reachedTheEnd = true;
    }
    if (this.phase === 4) {
      const statusUpdated = this.contractState(this.contract);
      const appaltoData = {
        data: {
          id: contractId,
          type: 'contracts',
          attributes: {
            name: this.contract.name,
            dta_end_of_works: moment(this.contract.dta_end_of_works).format('YYYY-MM-DD'),
            sended: this.contract.sended,
            status: statusUpdated, // 'attivo',
            customer_id: this.contract.customer_id,
            products: this.selectedProducts
          }
        }
      };

      this.jsonapiservice.updateEntity('contracts', contractId, appaltoData).subscribe((r: any) => {
 //       this.notifier.notify('info', 'Il tuo appalto è aggiornato');
        this.getContractEntity(contractId);
        this.setAction('edit');
        if (this.contract.sended) {
          this.inviaAppalto();
        }
        this.phase = 3;
      }, (error: ErrorResponce) => { this.jsonapiservice.handlingError(error); });
    }
    if (r) {
      this.phase = 3;
      if (this.action !== '') {
        this.messageservice.filter({ event: 'enable_menu' });
        // Toast
        this.notifier.notify('success', this.translatedResources.contract_updated);
      }
    }
  }

  public applyFilters(filters: Array<Filter> = [], table = 'products') {
    this.showSelectedProducts = false;
    if (this.filterManufacturer > 0 && table === 'products') {
      filters.push({ name: 'manufacturer_id', value: this.filterManufacturer.toString() });
    }
    if (this.search !== '') {
      filters.push({ name: 'search', value: this.search });
    }
    if (table === 'products') {
      this.getProducts(1, this.recordPerPage, filters);
    } else {
      this.getCustomers(filters);
    }
  }
  private getCustomers(filters?: Array<Filter>) {
    this.customers = [];
    const orderby = this.orderDirection + this.orderField;
    this.loading = true;
    this.jsonapiservice.getCollection('customers', filters, orderby)
    .subscribe((data: Collection) => {
        data.data.forEach((item) => {
          this.customers.push({
            id: item.id,
            rag_soc: item.attributes.rag_soc,
            sede: item.attributes.comune,
            tipologia: item.attributes.customer_type,
            last_contract: item.attributes.last_contract
          });
        });
        this.loading = false;
      }, (error: ErrorResponce) => { this.jsonapiservice.handlingError(error); });
  }

  public pageClick($event) {
    this.paginator = null;
    this.products = null;
    this.getProducts($event);
  }

  public openDialog() {
    const dialogRef = this.dialog.open(NuovoClienteComponent, {
      height: 'auto',
      width: '500px',
    });

    dialogRef.afterClosed().pipe(
      filter(result => result === 'customer')
    ).subscribe((result: Array<string>) => {
      this.notifier.notify('success', this.translatedResources.newcustomerinserted);
      this.getCustomers();
    });
  }

  public customerSelected(customerId) {
    this.getCustomer(customerId);
  }
  /*
    public productIsSelected(productId) {
      let r = false;
      this.selectedProducts.forEach((item) => {
        if (Number(item) === Number(productId)) {
            r = true;
        }
      });
      return r;
    } */


  public removeProduct(productId) {
    const contractId = this.route.snapshot.paramMap.get('id');
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        title: this.translatedResources.confirmdeleteproduct,
        message: this.translatedResources.confirmdeleteproductmessage
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        const i = _.findIndex(this.contract.rows, ['applicator_products_id', productId]);
        if (i > -1) {
          this.contract.rows.splice(i, 1);
          this.selectedProducts.splice(i, 1);
        }

        const appaltoData = {
          data: {
            id: contractId,
            type: 'contracts',
            attributes: {
              name: this.contract.name,
              dta_end_of_works: moment(this.contract.dta_end_of_works).format('YYYY-MM-DD'),
              sended: false,
              status: this.contract.status,
              customer_id: this.contract.customer_id,
              products: this.selectedProducts
            }
          }
        };
        this.jsonapiservice.updateEntity('contracts', contractId, appaltoData).subscribe((r: any) => {
          this.notifier.notify('info', this.translatedResources.productdeleted);
        }, (error: ErrorResponce) => { this.jsonapiservice.handlingError(error); });
      }
    });


  }

 /*  public downloadContracts() {
    this.downloadservice.setValue('download', { products: this.selectedProducts });
    const newWindow = window.open('download');
  } */
  public downloadContracts() {
    const sproducts = [];
    this.selectedProducts.forEach(element => {
      const i = ( _.findIndex(this.contract.rows, (o) => { return o.applicator_products_id == element; }));
      sproducts.push(Number(this.contract.rows[i].product_id));
    });
    console.log('contract rows', this.contract.rows);
    console.log('prodotti selezionati',  this.selectedProducts);
    console.log('to download', sproducts);

    this.downloadservice.setValue('download', { products: sproducts });
    const newWindow = window.open('download');
  }

  public download(productId) {
    this.downloadservice.setValue('download', { products: [productId] });
    const newWindow = window.open('download');
  }

  public eliminaAppalto() {
    const contractId = this.route.snapshot.paramMap.get('id');
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        title: this.translatedResources.confirmdeletecontract,
        message: this.translatedResources.confirmdeleteproductmessage
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.jsonapiservice.deleteEntity('contracts', contractId).subscribe((r: any) => {
          this.notifier.notify('info',  this.translatedResources.contractdeleted);
          setTimeout(() => {
            this.router.navigateByUrl('/applicator/appalti');
          }, 2000);
        }, (error: ErrorResponce) => { this.jsonapiservice.handlingError(error); });
      }
    });


  }

  public inviaAppalto() {
    const contractId = this.route.snapshot.paramMap.get('id');

    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {
        title: this.translatedResources.confirmsendtitle,
        message: this.translatedResources.confirmsendmessage
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.notifier.notify('success', this.translatedResources.sending);
        const appaltoData = {
          data: {
            id: contractId,
            type: 'contracts',
            attributes: {}
          }
        };
        this.jsonapiservice.postEntity('contracts', appaltoData, `sendpec/${contractId}`).subscribe(
          (r) => {
            this.notifier.notify('success', this.translatedResources.sendingsuccess);
            this.getPecsHistory();
          }, (error: ErrorResponce) => this.jsonapiservice.handlingError(error)
        );
      }
    });

  }

}
