import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'isSelected' })
export class IsSelected implements PipeTransform {
    transform(value: any, args: any[]): any {
        let r = false;
        args.forEach((item) => {
          //console.log('item :', item, 'value :', value);
          if (Number(item) === Number(value)) {
              r = true;
          }
        });
        return r;
    }
}
