import { ConfirmDeleteComponent } from './../../../../components/confirm-delete/confirm-delete.component';
import { Attributes } from './../../../../models/user.model';
import { JsonapiService, Filter } from './../../../../services/jsonapi.service';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/services/MessageService.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Entity, ErrorResponce } from 'src/app/models/jsonapi.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { MatDialog } from '@angular/material/dialog';
import { ContractCollection } from 'src/app/models/contract.model';

@Component({
  selector: 'app-scheda-cliente',
  templateUrl: './scheda-cliente.component.html',
  styleUrls: ['./scheda-cliente.component.scss']
})

export class SchedaClienteComponent implements OnInit {
  public customer;
  private readonly notifier: NotifierService;
  constructor(
    private jsonapiservice: JsonapiService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private notifierService: NotifierService,
    public dialog: MatDialog,
  ) {
    this.notifier = notifierService;
  }

  public tab = 'cliente';
  customerFormGroup = new FormGroup({
    rag_soc: new FormControl(),
    comune: new FormControl(),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern("[^ @]*@[^ @]*")
    ]),
    piva: new FormControl('', [
      Validators.required,
      Validators.pattern('^([0-9]{11})|([A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1})$')
    ]),
    emailtype : new FormControl('' , [ Validators.required ]),
    customer_type: new FormControl('' , [ Validators.required ]),
    note: new FormControl()
  });

  public contracts: Array<any>;


  ngOnInit() {
    const customerId = this.route.snapshot.paramMap.get('id');
    this.getCustomer(customerId);
    this.getContracts(customerId);
    this.messageService.filter({ event: 'selected_route', route: '/applicator/customers'});
    this.messageService.filter({ event: 'enable_menu' });
  }

  public getCustomer(customerId) {
    this.jsonapiservice.getEntity('customers', customerId).subscribe((customer: Entity) => {
      this.customer = customer.data;
      this.customerFormGroup.controls['rag_soc'].setValue(this.customer.attributes.rag_soc);
      this.customerFormGroup.controls['comune'].setValue(this.customer.attributes.comune);
      this.customerFormGroup.controls['email'].setValue(this.customer.attributes.email);
      this.customerFormGroup.controls['emailtype'].setValue(this.customer.attributes.emailtype);
      this.customerFormGroup.controls['piva'].setValue(this.customer.attributes.piva);
      this.customerFormGroup.controls['customer_type'].setValue(this.customer.attributes.customer_type);
      this.customerFormGroup.controls['note'].setValue(this.customer.attributes.note);
    });
  }
  public getContracts(customerId) {
    const filters: Array<Filter> = [
        { name: 'customer_id', value: customerId }
      ];
    this.jsonapiservice.getCollection('contracts', filters).subscribe((r: ContractCollection) => {
        this.contracts = r.data;
      }, (error: ErrorResponce) => { this.jsonapiservice.handlingError(error); });
  }

  delete() {
    const customerId = this.route.snapshot.paramMap.get('id');
    this.jsonapiservice.translate('i18n.applicator.confirmdeletecustomer').subscribe((value) => {
      const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
        data: {
          message: value
        },
      /*   height: '400px',
           width: '600px', */
      });

      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.jsonapiservice.deleteEntity('customers', customerId).subscribe((data) => {
            //this.notifier.notify('success', 'Scheda cliente eliminata correttamente');
            this.jsonapiservice.notify('success', 'i18n.common.customerdelete');
            setTimeout(() => {
              this.router.navigateByUrl('/applicator/customers');
            }, 1000) ;
          }, (error: ErrorResponce) => { this.jsonapiservice.handlingError(error); });
        }
      });
    });

  }

  setTab(tab: string) {
    this.tab = tab;
  }

  save() {
    const customerId = this.route.snapshot.paramMap.get('id');
    const updateData = {
      data: {
        type: 'customers',
        id: customerId,
        attributes: {
          rag_soc: this.customerFormGroup.value.rag_soc,
          piva: this.customerFormGroup.value.piva,
          email: this.customerFormGroup.value.email,
          emailtype: this.customerFormGroup.value.emailtype,
          customer_type: this.customerFormGroup.value.customer_type,
          comune: this.customerFormGroup.value.comune,
          note: this.customerFormGroup.value.note,
        }
      }
    };
    this.jsonapiservice.updateEntity('customers', customerId, updateData).subscribe((resp) => {
      //this.notifier.notify('success', 'Scheda cliente aggiornata correttamente');
      this.jsonapiservice.notify('success', 'i18n.common.customeradjurned');
      setTimeout(() => {
        this.router.navigateByUrl('/applicator/customers');
      }, 1000) ;
    }, (error: ErrorResponce) => { this.jsonapiservice.handlingError(error); });
  }

  setCustomerType(type: string) {
    this.customerFormGroup.controls['customer_type'].setValue(type);
  }


}
