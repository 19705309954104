// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  env: 'prod',
  version: "20230824.0",
  socket: true,
  stage: {
    client_id: 4,
    client_secret: "nLuDYw5d4g3gGbUFm2K4DRS1p0CU3DK4BF2WodhW",
    oauth_base_url: "https://backend.safeup.netseven.it/oauth",
    auth_base_url: "https://backend.safeup.netseven.it/api/auth",
    base_url: "https://backend.safeup.netseven.it/api",
    domain: "https://backend.safeup.netseven.it",
    version: "v1",
    websocket: "broadcast.safeup.netseven.it",
  },
  // stage: {
  //   client_id: 2,
  //   client_secret: 'f8SRut8xlBYVAJqI67tQ6ExIWNAlGG0Mapw6S8fA',
  //   oauth_base_url: 'https://backend.safeup.netseven.it/oauth',
  //   auth_base_url: 'https://backend.safeup.netseven.it/api/auth',
  //   base_url: 'https://backend.safeup.netseven.it/api',
  //   domain: 'https://backend.safeup.netseven.it',
  //   version: 'v1',
  //   websocket: 'broadcast.safeup.netseven.it',
  // },
  prod: {
    client_id: 2,
    client_secret: 'QWz20mK27arHkxPsMoU09Y18bpWpd5PGRZwWQHv5',
    oauth_base_url: 'https://backend.safeup-prod.netseven.it/oauth',
    auth_base_url: 'https://backend.safeup-prod.netseven.it/api/auth',
    base_url: 'https://backend.safeup-prod.netseven.it/api',
    domain: 'https://backend.safeup-prod.netseven.it',
    version: 'v1',
    websocket: 'broadcast.safeup-prod.netseven.it',
  },
  local: {
    client_id: 2,
    client_secret: 'JWwScMa5GPKdrz50LBCSMWpNBM1JMje15faLNEsL',
    oauth_base_url: 'http://safeup.local/oauth',
    auth_base_url: 'http://safeup.local/api/auth',
    base_url: 'http://safeup.local/api',
    domain: 'http://safeup.local',
    version: 'v1',
    websocket: '127.0.0.1:1973'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
