import { When } from './pipes/when.pipe';
import { environment } from './../environments/environment';
import {enableProdMode} from '@angular/core';
import { ReportComponent } from './pages/webadmin/report/report.component';
import { LanguageService } from './services/language.service';
import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { RichiestaComponent } from './pages/supporto/richiesta/richiesta.component';
import { SchedaTicketComponent } from './pages/webadmin/tickets/scheda-ticket/scheda-ticket.component';
import { TicketsComponent } from './pages/webadmin/tickets/tickets.component';
import { SendEmailComponent } from './components/send-email/send-email.component';
import { NuovoProduttoreComponent } from './pages/webadmin/produttori/nuovo-produttore/nuovo-produttore.component';
import { EditPasswordComponent } from './pages/edit-password/edit-password.component';
import { SocketService } from './services/socket.service';
import { RiepilogoComponent } from './pages/webadmin/abbonamenti/riepilogo/riepilogo.component';
import { SchedaProduttoreComponent } from './pages/webadmin/produttori/scheda-produttore/scheda-produttore.component';
import { SchedaNegozioComponent } from './pages/webadmin/negozi/scheda-negozio/scheda-negozio.component';
import { SchedaProdottoComponent as AdminSchedaProdotto } from './pages/webadmin/prodotti/scheda-prodotto/scheda-prodotto.component';
import { NuovoAbbonamentoComponent } from './pages/webadmin/abbonamenti/nuovo-abbonamento/nuovo-abbonamento.component';
import { IsSelected } from './pipes/isSelected.pipe';
import { DownloadService } from './services/Download.service';
import { ProfiloComponent as ApplicatorProfilo} from './pages/applicators/profilo/profilo.component';
import { ConfirmDeleteComponent } from './components/confirm-delete/confirm-delete.component';
import { ProductsSearchDialogComponent } from './components/products-search-dialog/products-search-dialog.component';
import { SchedaProdottoComponent } from './pages/applicators/prodotti/scheda-prodotto/scheda-prodotto.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { JsonapiService } from './services/jsonapi.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule, MatAutocompleteModule, MatDialogModule} from '@angular/material';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import { AuthService } from './services/auth.service';
import { TokenService } from './services/token.service';
import { AuthGuard } from './auth.guard';
import { OauthService } from './services/oauth.service';
import { LocalStorageService } from 'ngx-store';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LogoutComponent } from './pages/logout/logout.component';
import { HeaderComponent } from './components/header/header.component';
import { ApplicatoriComponent } from './pages/webadmin/applicatori/applicatori.component';
import { NegoziComponent } from './pages/webadmin/negozi/negozi.component';
import { ProdottiComponent } from './pages/webadmin/prodotti/prodotti.component';
import { ProduttoriComponent  as webadminProduttori} from './pages/webadmin/produttori/produttori.component';
import { AppaltiComponent } from './pages/applicators/appalti/appalti.component';
import { ClientiComponent } from './pages/applicators/clienti/clienti.component';
import { ClientiComponent as ShopClienti } from './pages/shops/clienti/clienti.component';
import { SchedaClienteComponent as ShopChedaCliente} from './pages/shops/clienti/scheda-cliente/scheda-cliente.component';
import { ProdottiComponent  as ShopProdotti } from './pages/shops/prodotti/prodotti.component';
import { OrdiniComponent } from './pages/shops/ordini/ordini.component';
import { SupportoComponent } from './pages/supporto/supporto.component';
import { NuovoAppaltoComponent } from './pages/applicators/appalti/nuovo-appalto/nuovo-appalto.component';
import { ProdottiComponent  as ApplicatorsProdotti } from './pages/applicators/prodotti/prodotti.component';
import { ClientiComponent as ApplicatoriClienti } from './pages/applicators/clienti/clienti.component';
import { MatPaginatorModule } from '@angular/material';
import { SchedaAppaltoComponent } from './pages/applicators/appalti/scheda-appalto/scheda-appalto.component';
import { registerLocaleData } from '@angular/common';
import { MessageService } from './services/MessageService.service';
import localeIt from '@angular/common/locales/it';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { SchedaClienteComponent } from './pages/applicators/clienti/scheda-cliente/scheda-cliente.component';
import { NuovoClienteComponent as ApplicatorNuovoCliente } from './pages/applicators/clienti/nuovo-cliente/nuovo-cliente.component';
import { LocalJsonService } from './services/local.json.service';
import { LicenzaComponent } from './pages/applicators/licenza/licenza.component';
import { DownloadComponent } from './pages/download/download.component';
import { DateDiff } from './pipes/dateDiff.pipe';
import { SchedaApplicatoreComponent } from './pages/webadmin/applicatori/scheda-applicatore/scheda-applicatore.component';
import { NuovoProdottoComponent } from './pages/webadmin/prodotti/nuovo-prodotto/nuovo-prodotto.component';
import { ProductSuggestComponent } from './pages/applicators/prodotti/product-suggest/product-suggest.component';
import { SubscribeExpiredComponent } from './pages/subscribe-expired/subscribe-expired.component';
import { ProfiloComponent  as AdminProfiloComponent } from './pages/webadmin/profilo/profilo.component';
import { SchedaOrdineComponent } from './pages/shops/ordini/scheda-ordine/scheda-ordine.component';
import { SchedaProdottoComponent as ShopSchedaProdotto} from './pages/shops/prodotti/scheda-prodotto/scheda-prodotto.component';
import { ImportazioneSdsComponent } from './pages/webadmin/importazione-sds/importazione-sds.component';
import { SchedaImportazioneSdsComponent } from './pages/webadmin/importazione-sds/scheda-importazione-sds/scheda-importazione-sds.component';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { LangselectorComponent } from './components/langselector/langselector.component';


registerLocaleData(localeIt, 'it');

enableProdMode();
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

/**
 * Custom angular notifier options
 */
const customNotifierOptions: NotifierOptions = {
  position: {
		horizontal: {
			position: 'middle',
			distance: 0
		},
		vertical: {
			position: 'top',
			distance: 80,
			gap: 10
		}
	},
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

const PROVIDERS = [
  AuthService,
  AuthGuard,
  TokenService,
  LocalStorageService,
  OauthService,
  HttpClientModule,
  MessageService,
  JsonapiService,
  LocalJsonService,
  DownloadService,
  SocketService,
  LanguageService,
  TranslateService
];

const DECLARATIONS = [
  IsSelected, // questa e' una pipe
  DateDiff, // questa e' una pipe
  When, // questa e' una pipe
  AppComponent,
  LangselectorComponent,
  LoginComponent,
  DashboardComponent,
  RiepilogoComponent,
  LogoutComponent,
  HeaderComponent,
  PaginatorComponent,
  ApplicatoriComponent,
  ApplicatorsProdotti,
  ApplicatoriClienti,
  ShopClienti,
  ShopChedaCliente,
  ShopSchedaProdotto,
  ShopProdotti,
  OrdiniComponent,
  ApplicatorNuovoCliente,
  NegoziComponent,
  ProdottiComponent,
  webadminProduttori,
  AppaltiComponent,
  SchedaProdottoComponent,
  ClientiComponent,
  NuovoAbbonamentoComponent,
  EditPasswordComponent,
  SupportoComponent,
  NuovoAppaltoComponent,
  ProductsSearchDialogComponent,
  ConfirmDeleteComponent,
  NuovoProdottoComponent,
  NuovoProduttoreComponent,
  SubscribeExpiredComponent,
  SchedaOrdineComponent,
  AdminProfiloComponent,
  AdminSchedaProdotto,
  ImportazioneSdsComponent, SchedaImportazioneSdsComponent,
  TicketsComponent, SchedaTicketComponent, RichiestaComponent,
  SchedaApplicatoreComponent, SchedaNegozioComponent, SchedaProduttoreComponent, ProductSuggestComponent, SendEmailComponent,
  SchedaAppaltoComponent, SchedaClienteComponent, ApplicatorProfilo, LicenzaComponent, DownloadComponent, ReportComponent];

const ENTRYCOMPONENTS = [
  NuovoProduttoreComponent,
  ProductsSearchDialogComponent,
  ConfirmDeleteComponent,
  ProductSuggestComponent,
  SendEmailComponent] ;

const IMPORTS =  [
  BrowserModule, BrowserAnimationsModule, FormsModule, ReactiveFormsModule, NotifierModule.withConfig(customNotifierOptions),
  MatButtonModule, MatAutocompleteModule, MatCardModule, MatInputModule, MatSnackBarModule, MatToolbarModule,
  AppRoutingModule, HttpClientModule, MatPaginatorModule, MatDialogModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: (createTranslateLoader),
      deps: [HttpClient]
    }
  }),
  NgxEchartsModule.forRoot({
    echarts
  })
];

@NgModule({
  declarations: DECLARATIONS,
  imports: IMPORTS,
  providers: PROVIDERS,
  entryComponents: ENTRYCOMPONENTS,
  bootstrap: [AppComponent]
})
export class AppModule {

 }
