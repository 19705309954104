import { Collection } from 'src/app/models/jsonapi.model';
import { Entity, ErrorResponce, CollectionPaginated } from './../../../../models/jsonapi.model';
import { Component, OnInit } from '@angular/core';
import { Paginator } from 'src/app/models/paginator.model';
import { JsonapiService, Filter } from 'src/app/services/jsonapi.service';
import { MessageService } from 'src/app/services/MessageService.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { EmailValidator } from '@angular/forms';

@Component({
  selector: 'app-scheda-importazione-sds',
  templateUrl: './scheda-importazione-sds.component.html',
  styleUrls: ['./scheda-importazione-sds.component.scss']
})
export class SchedaImportazioneSdsComponent implements OnInit {
  public collection = [];
  public entity: Entity;
  public paginator: Paginator;
  public orderDirection = '-';
  public orderField = 'id';
  public orderIcon = '';
  public recordPerPage = 10;
  public search = '';
  public loading = true;

  constructor(
    private jsonapiservice: JsonapiService,
    private messageservice: MessageService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.messageservice.filter({ event: 'enable_menu' });
    this.messageservice.filter({ event: 'selected_route', route: '/admin/importazioni-schede-sds' });
    const entityId = this.route.snapshot.paramMap.get('id');
    this.getCollection();
    this.getEntity(entityId);
  }

  public getCollection(filters?: Array<Filter>) {
  const entityId = this.route.snapshot.paramMap.get('id');
  this.loading = true;
  this.collection = [];
  const orderby = this.orderDirection + this.orderField;
  if (!filters) {
    filters = [];
    filters.push({name: 'sdsid', value: entityId});
  }
  this.jsonapiservice.getCollection('sds-import-entries', filters)
  .subscribe((data: Collection) => {
   // this.paginator = data.meta.page;
    this.collection = data.data;
    this.loading = false;
    console.log(this.collection);
  }, (error: ErrorResponce) => { this.jsonapiservice.handlingError(error); this.loading = false;  });
}
  public getEntity(entityId) {
    this.loading = true;
    this.jsonapiservice.getEntity('sds-import', entityId).subscribe((entity: Entity) => {
      this.entity = entity;
      this.loading = false;
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    });
  }
  orderBy(orderField) {
    if (this.orderField !== orderField) {
      this.orderField = orderField;
      this.orderDirection = '';
      this.orderIcon = 'fa-arrow-up';
    } else {
      this.orderDirection = (this.orderDirection === '-') ? '' : '-';
      this.orderIcon = (this.orderDirection === '-') ? 'fa-arrow-down' : 'fa-arrow-up';
    }
    this.getCollection();
  }

    // public messageTranslate(entity) {
    //      let message = '';
    //      if (entity.attributes.message) {
    //         if (entity.attributes.message.success) {
    //           message = message + ' archived:' + entity.attributes.message.archived + ' total files:' + entity.attributes.message.files;
    //         }
    //         if (entity.attributes.message.problems){
    //           message = message + ' problems:' + entity.attributes.message.problems;
    //         }
    //         message = message + ' email: ' +  entity.attributes.message.email;
    //       }
    //      return message;
    // }

  pageClick($event) {
    this.paginator  = null;
    this.getCollection($event);
  }

  public getLabelClass(entity) {
    if (entity.attributes.entry) {
      if (entity.attributes.entry < 200) {
        return 'label info';
      }
      if (entity.attributes.entry < 300) {
        return 'label warning';
      }
      if (entity.attributes.entry < 400) {
        return 'label error';
      }
    }
  }

  isObject(item): boolean { return typeof item === 'object'; }
}
