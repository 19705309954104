import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss']
})

export class SendEmailComponent implements OnInit {
  public title = 'Invia la documentazione';
  FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
    ]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {

    }
    ngOnInit() {
    }
}

