import { ErrorResponce } from './../../../../models/jsonapi.model';
import { Component, OnInit, Inject } from '@angular/core';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { MessageService } from 'src/app/services/MessageService.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Collection } from 'src/app/models/jsonapi.model';


@Component({
  selector: 'app-nuovo-prodotto',
  templateUrl: './nuovo-prodotto.component.html',
  styleUrls: ['./nuovo-prodotto.component.scss']
})
export class NuovoProdottoComponent implements OnInit {
  public manufacturers = null;
  private type = 'products';
  private entity = null;
  FormGroup = new FormGroup({
    name: new FormControl(),
    sku: new FormControl('', [Validators.required]),
    manufacturer_id: new FormControl('', [Validators.required])
  });
  constructor(
    private jsonapiservice: JsonapiService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<NuovoProdottoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  ngOnInit() {
    this.getManufacturers();
  }

  public save() {
    const data = {
      data:
      {
        type: this.type,
        attributes: {
          name: this.FormGroup.value.name,
          sku: this.FormGroup.value.sku,
          product_type: '',
          product_updated_at: moment().format('YYYY-MM-DD'),
          manufacturer_id: this.FormGroup.value.manufacturer_id
        }
      }
    };
    this.jsonapiservice.postEntity(this.type, data).subscribe((resp) => {
      this.entity = resp;
      this.dialogRef.close(this.entity);
    }, (error) => {
      console.error(error);
      // this.notifier.notify('error',  error.statusText);
    });
  }

  private getManufacturers() {
    this.jsonapiservice.getCollection('manufacturers', null, 'rag_soc')
    .subscribe((data: Collection) => {
      this.manufacturers = [];
      data.data.forEach((item) => {
        this.manufacturers.push({ id: item.id, name: item.attributes.rag_soc });
      });
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    });
  }
}
