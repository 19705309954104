
import { ProductCollection, Product, ProductEntity } from './../../models/product.model';
import { JsonapiService, Filter } from './../../services/jsonapi.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-products-search-dialog',
  templateUrl: './products-search-dialog.component.html',
  styleUrls: ['./products-search-dialog.component.scss']
})
export class ProductsSearchDialogComponent implements OnInit {
  public products: Array<ProductEntity> = null;
  public selectedProducts = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private jsonapiservice: JsonapiService
    ) {}

  ngOnInit() {
    this.getProducts();
  }
  getProducts() {
    const filters: Array<Filter> = [];
    if (this.data.search !== '') {
      filters.push({name: 'search', value: this.data.search});
    }
    if (this.data.manufacturer > 0) {
      filters.push({ name: 'manufacturer_id', value: this.data.manufacturer.toString()});
    }
    this.jsonapiservice.getCollection('products', filters)
      .subscribe((responce: ProductCollection) => {
        this.products = responce.data;
      });
  }

  selectProduct(sku) {
    const index = this.selectedProducts.indexOf(sku);
    if (index > 0) {
       this.selectedProducts.splice(index, 1);
    } else {
      this.selectedProducts.push(sku);
    }
  }
}
