import { LanguageService } from './../../../services/language.service';
import { ErrorResponce } from './../../../models/jsonapi.model';
import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { User, IUser } from 'src/app/models/user.model';
import { Entity } from 'src/app/models/jsonapi.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { JsonapiService } from 'src/app/services/jsonapi.service';
import { LocalJsonService } from 'src/app/services/local.json.service';

@Component({
  selector: 'app-profilo',
  templateUrl: './profilo.component.html',
  styleUrls: ['./profilo.component.scss']
})
export class ProfiloComponent implements OnInit {
  private readonly notifier: NotifierService;
  public User: User;
  public Applicator: Entity = null;
  public province: Array<any>;
  public lang: string;
  applicatorFromGroup = new FormGroup({
    rag_soc: new FormControl(),
    comune: new FormControl(),
    pec: new FormControl('', [
      Validators.required,
      Validators.pattern("[^ @]*@[^ @]*")
    ]),
    email: new FormControl('', [
      Validators.pattern("[^ @]*@[^ @]*")
    ]),
    indirizzo: new FormControl(),
    provincia: new FormControl(),
    cap: new FormControl(),
    ncivico: new FormControl(),
    piva: new FormControl('', [
      Validators.required,
    ]),
  });

  constructor(
    private auth: AuthService,
    private jsonapiservice: JsonapiService,
    private languageservice: LanguageService,
    private localjsonservice: LocalJsonService,
    private notifierService: NotifierService,
  ) {
    this.notifier = notifierService;
   }

  ngOnInit() {
    this.auth.getUser().subscribe((user: IUser) => {
      this.User = new User(user);
      // ricerca applicatore
      this.lang = this.languageservice.getLang();

    });
  }

  public setLang(lang) {
    this.languageservice.setLanguage(lang);
  }

  public save() {
    const updateData = {
      data: {
        type: this.Applicator.data.type,
        id: this.Applicator.data.id,
        attributes: {
          rag_soc: this.applicatorFromGroup.value.rag_soc,
          piva: this.applicatorFromGroup.value.piva,
          pec: this.applicatorFromGroup.value.pec,
          email: this.applicatorFromGroup.value.email,
          indirizzo: this.applicatorFromGroup.value.indirizzo,
          comune: this.applicatorFromGroup.value.comune,
          ncivico: this.applicatorFromGroup.value.ncivico,
          provincia: this.applicatorFromGroup.value.provincia,
          cap: this.applicatorFromGroup.value.cap,
      //    note: this.applicatorFromGroup.value.note
        }
      }
    };
    this.jsonapiservice.updateEntity(this.Applicator.data.type, this.Applicator.data.id, updateData).subscribe((resp) => {
       //this.notifier.notify('success', 'Profilo aggiornato correttamente');
       this.jsonapiservice.notify('success', 'i18n.common.profileadjurned');
    }, (error: ErrorResponce) => {
      this.jsonapiservice.handlingError(error);
    });
  }
}
